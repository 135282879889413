import { RootState } from '../../../redux/configureStore'
import {
  setEmail,
  setIsLoggedIn,
  setMainAppToken,
  setPrimaryReferralCode,
  setProfilePictureUrl,
  setRefferCode,
  setWeb3AuthToken,
} from '../../../redux/slices/userSlice'
import { api } from '../../api'

export interface IUser {
  _id: string
  profileId: string
  address: string
  email: string
  holdingsHistory: number[]
  scoreTokensHoldingHistory: number[]
  elligibleForGiftCard: boolean
}

export interface IKYCResponse {
  status: string
}

interface ISendEmail {
  projectId: string
  idoNumber: number
}

interface IRegisterEmail {
  appPubKey: string
  web3AuthToken: string
  email: string
}

interface IRegisterAccount {
  signature: string
  address: string
  chainId: number
}

interface IRegisterAccountResponse {
  addressToken: {
    expires: string
    expiresPrettyPrint: string
    token: string
  }
  code: string
}

interface IRegisterEmailResponse {
  token: {
    expires: string
    expiresPrettyPrint: string
    token: string
  }
  user: {
    profilePicture: string
    email: string
  }
}

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    // * For get requests
    fetchUserScore: build.query<number, string>({
      query: address => `profile/score/${address}`,
    }),
    isKycVerified: build.query<IKYCResponse, string>({
      query: refId => `profile/kyc/${refId}`,
    }),
    fetchUserHoldings: build.query<number[][], string>({
      query: address => `profile/holdings`,
    }),

    sendEmail: build.query<void, ISendEmail>({
      query: ({ projectId, idoNumber }) =>
        `auth/send-mail?projectId=${projectId}&idoNumber=${idoNumber}`,
    }),

    // update holding patch request
    updateHolding: build.mutation({
      query: () => ({
        url: `profile/update/user/holdings`,
        method: 'POST',
      }),
    }),
    createAccount: build.mutation<IUser, { email: string; address: string }>({
      query: user => ({
        url: 'profile',
        method: 'POST',
        body: user,
      }),
    }),

    registerUserAccount: build.mutation<
      IRegisterAccountResponse,
      IRegisterAccount
    >({
      query: ({ address, signature, chainId }) =>
        `/auth/register-account?signature=${signature}&address=${address}&chainId=${chainId}`,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(setMainAppToken(data.addressToken.token))
          dispatch(setRefferCode(data.code))
          dispatch(setPrimaryReferralCode(data.code))
          console.log(
            '🚀 ~ file: index.ts:115 ~ onQueryStarted ~ data.code:',
            data.code,
          )
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching post!'))
          console.error(err, 'could not fetch token from backend')
        }
      },
    }),

    registerUserEmail: build.mutation<IRegisterEmailResponse, IRegisterEmail>({
      query: data => ({
        url: 'auth/authenticate',
        method: 'POST',
        body: data,
      }),
      // query: (body) => `auth/authenticate`,
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'))
        try {
          const { data } = await queryFulfilled
          console.log('🚀 ~ file: index.ts:125 ~ onQueryStarted ~ data:', data)
          // `onSuccess` side-effect
          dispatch(setMainAppToken(data.token.token))
          dispatch(setEmail(data.user.email))
          dispatch(setIsLoggedIn(true))
          dispatch(setProfilePictureUrl(data.user.profilePicture))
          console.log(
            '🚀 ~ file: index.ts:73 ~ onQueryStarted2 ~ data.addressToken.token:',
            data.token.token,
          )
        } catch (err) {
          // `onError` side-effect
          // dispatch(messageCreated('Error fetching post!'))
          console.log('🚀 ~ file: index.ts:131 ~ onQueryStarted ~ err, auth/authenticate:', err)

          let appState = await getState() as RootState

          console.error(appState, "app state from error")

          await appState.app.web3AuthInstance?.logout()
          dispatch(setWeb3AuthToken(''))
          dispatch(setEmail(''))
          dispatch(setIsLoggedIn(false))
          dispatch(setProfilePictureUrl(''))
        }
      },
    }),

    // * For post requests
    // registerUserAccount1: build.mutation<
    //   IRegisterAccountResponse,
    //   IRegisterAccount
    // >({
    //   query: ({ address, signature }) => ({
    //     url: `/auth/register-account?signature=${signature}&address=${address}`,
    //     method: 'GET',
    //     async onQueryStarted(id, { dispatch, queryFulfilled }) {
    //       // `onStart` side-effect
    //       // dispatch(messageCreated('Fetching post...'))
    //       try {
    //         const { data } = await queryFulfilled
    //         // `onSuccess` side-effect
    //         // dispatch(messageCreated('Post received!'))
    //       } catch (err) {
    //         // `onError` side-effect
    //         // dispatch(messageCreated('Error fetching post!'))
    //       }
    //     },
    //   }),
    // }),

    authenticateUser: build.mutation<
      {
        user: IUser
        token: {
          expires: string
          expiresPrettyPrint: string
          token: string
        }
      },
      {
        email: string
        address: string
        web3AuthToken: string
        appPubKey: string
      }
    >({
      query: payload => ({
        url: 'auth/authenticate',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useSendEmailQuery,
  useLazySendEmailQuery,
  useFetchUserHoldingsQuery,
  useLazyFetchUserHoldingsQuery,
  useFetchUserScoreQuery,
  useLazyFetchUserScoreQuery,
  useIsKycVerifiedQuery,
  useLazyIsKycVerifiedQuery,
  useAuthenticateUserMutation,
  useRegisterUserAccountMutation,
  useRegisterUserEmailMutation,
  useUpdateHoldingMutation,
} = userApi
