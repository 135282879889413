import { Box, Typography, useMediaQuery } from '@mui/material'
import {
  ChangeWallet,
  ChangeWalletPattern,
  FilledBlackArrow,
  SolanaLogo,
  SolanaPattern,
} from '../../assets'
import GreenButton from '../../components/GreenButton'
import { useState } from 'react'

const WalletBox = ({ onSubmit, solanaAddress, setSolanaAddress }: any) => {
  const [isChainSwitched, setIsChainSwitched] = useState(true)
  const isDown1000 = useMediaQuery('(max-width:1000px)')

  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        flex: 1,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: isDown1000 ? '32px' : '0px',
        width: '100%',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        textAlign={'center'}
        paddingX={isDown1000 ? '32px' : '10%'}
        width={'100%'}
      >
        {isChainSwitched ? (
          <img
            src={isDown1000 ? SolanaPattern : SolanaLogo}
            alt="solana-logo"
            style={{
              width: isDown1000 ? '208px' : '64px',
              height: isDown1000 ? '208px' : '64px',
            }}
          />
        ) : (
          <img
            src={isDown1000 ? ChangeWalletPattern : ChangeWallet}
            alt="Change Wallet"
            style={{
              width: isDown1000 ? '208px' : '64px',
              height: isDown1000 ? '208px' : '64px',
            }}
          />
        )}
        <Typography
          pt={isDown1000 ? 0 : '32px'}
          marginTop={isDown1000 ? '-45px' : '0px'}
          pb={isDown1000 ? 0 : '24px'}
          fontFamily={'Hellix'}
          fontSize={32}
          fontWeight={600}
          lineHeight={'40px'}
          color={'#FFFFFF'}
        >
          {isChainSwitched
            ? 'Solana address'
            : 'Change your Wallet to the BSC Network'}
        </Typography>
        {isChainSwitched && (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Box
              component={'input'}
              type="text"
              placeholder="Enter your Solana address"
              value={solanaAddress}
              onChange={(e: any) => setSolanaAddress(e.target?.value!)}
              sx={{
                width: '100%',
                height: '48px',
                padding: '0 16px',
                marginTop: isDown1000 ? '40px' : '8px',
                borderRadius: '8px',
                border: '1px solid rgba(255, 255, 255, 0.09)',
                backgroundColor: '#0F1113',
                color: '#7E8180',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '150%',
                caretColor: '#63EA71',
                caretShape: 'block',
                '&:focus-visible': {
                  outline: 'none',
                  border: '1px solid rgba(255, 255, 255, 0.09)',
                },
              }}
            />
          </Box>
        )}
        <GreenButton
          fs={15}
          lh="22.5px"
          fm="Inter"
          fw={400}
          br="24px"
          text={isChainSwitched ? 'Submit' : 'Switch'}
          mt={5}
          onClick={onSubmit}
          w="150px"
          IconStart={() => (
            <img src={FilledBlackArrow} alt="filled-black-arrow" />
          )}
        />
      </Box>
    </Box>
  )
}

export default WalletBox
