import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { scrollToTop } from '../../utils'
import FAQBox from './FAQBox'
import CompletedSalesReport from './CompletedSalesReport'

import Motion from '../../components/Motion/Motion'

const RevampedTransparency = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown400 = useMediaQuery('(max-width:400px)')
  const isDown600 = useMediaQuery('(max-width:600px)')

  const location: any = useLocation()

  React.useEffect(() => {
    scrollToTop()
  }, [location])

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      maxWidth={'996px'}
      minHeight={'100vh'}
      marginX={'20px'}
      paddingTop={'100px'}
      width={'100%'}
      paddingBottom={isDown1000 ? '48px' : '80px'}
    >
      <Motion from={{ y: 0, filter: 'blur(5px)' }} to={{ filter: 'blur(0px)' }}>
        <Typography
          sx={{
            fontFamily: 'Hellix',
            fontSize: isDown600 ? '32px' : '56px',
            fontWeight: 600,
            color: '#FFFFFF',
            margin: '0px',
            lineHeight: isDown600 ? '38.4px' : '61.6px',
            textAlign: 'left',
          }}
        >
          Transparency
          {isDown400 ? <br /> : ' '}
          FAQ
        </Typography>
      </Motion>

      <Box
        display={'grid'}
        gridTemplateColumns={isDown1000 ? '1fr' : '1fr 1fr'}
        gap={'8px'}
        marginTop={'32px'}
      >
        <FAQBox boxId={0} />
        <FAQBox boxId={1} />
        <FAQBox boxId={2} />
        <FAQBox boxId={3} />
      </Box>

      <Motion from={{ filter: 'blur(5px)' }} to={{ filter: 'blur(0px)' }}>
        <Typography
          sx={{
            fontFamily: 'Hellix',
            fontSize: isDown600 ? '26px' : '32px',
            fontWeight: 600,
            color: '#FFFFFF',
            margin: '0px',
            lineHeight: isDown600 ? '33.8px' : '39.68px',
            textAlign: 'left',
            paddingTop: '64px',
            paddingBottom: '32px',
          }}
        >
          Completed
          {isDown600 ? <br /> : ' '}
          Sales Reports
        </Typography>
      </Motion>

      <CompletedSalesReport />
    </Box>
  )
}

export default RevampedTransparency
