import React from 'react'
import { Box, Avatar, Typography, Button, useMediaQuery } from '@mui/material'
import GreenButton from '../../components/GreenButton'

const KYCnSocialBox: React.FC<{
  image: string
  heading: string
  subheading: string
  buttonText: string
  buttonAction: () => void
  KYCButton?: any
}> = ({ image, heading, subheading, buttonAction, buttonText, KYCButton }) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection:
          isDown1000 && buttonText !== 'Approved' ? 'column' : 'row',
        backgroundColor: '#0F1113',
        justifyContent: 'space-between',
        borderRadius: '12px',
        padding: isDown1000 ? '16px' : '24px',
        border: '0.5px solid rgba(255, 255, 255, 0.16)',
        width: '100%',
        overflowWrap: 'anywhere',
        gap: '12px',
        alignItems: isDown1000 ? 'flex-start' : 'center',
      }}
    >
      <Box display="flex" alignItems="start">
        <img
          src={image}
          alt={heading}
          style={{
            width: isDown1000 ? '40px' : '48px',
            height: isDown1000 ? '40px' : '48px',
          }}
        />
        <Box ml={isDown1000 ? '12px' : '16px'}>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            {heading}
          </Typography>
          <Typography
            sx={{
              color: '#7E8180',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '16.8px',
            }}
          >
            {subheading}
          </Typography>
        </Box>
      </Box>
      {KYCButton ? (
        <KYCButton />
      ) : (
        <GreenButton
          fs={buttonText === 'Approved' ? 12 : 15}
          lh="22.5px"
          fm="Inter"
          fw={buttonText === 'Approved' ? 500 : 400}
          br="24px"
          text={buttonText}
          h={buttonText === 'Approved' ? '22px' : '40px'}
          onClick={buttonAction}
          outlined={true}
          w={isDown1000 && buttonText !== 'Approved' ? '100%' : 'fit-content'}
          sx={{
            minWidth: '71px',
            border: buttonText === 'Approved' ? 'none' : '1px solid #08493C',
            padding: '3px 16px',
            color: '#63EA71',
            backgroundColor:
              buttonText === 'Approved'
                ? 'rgba(99, 234, 113, 0.1)'
                : 'transparent',
            '&:hover': {
              border: buttonText === 'Approved' ? 'none' : '1px solid #27B079',
              backgroundColor:
                buttonText === 'Approved'
                  ? 'rgba(99, 234, 113, 0.1)'
                  : 'transparent',
            },
          }}
        />
      )}
    </Box>
  )
}

export default KYCnSocialBox
