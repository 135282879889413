import {
  Typography,
  styled,
  Button,
  useTheme,
  useMediaQuery,
  Box,
  IconButton,
} from '@mui/material'
import { Container } from './styles'
import { Dollar25Icon } from '../../assets/'
import CloseIcon from '@mui/icons-material/Close'
//
const GiftCardBanner: React.FC<{
  onButtonClick: () => Promise<void>
  onCloseClick: (e: any) => void
}> = ({ onButtonClick, onCloseClick }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const SignUpButton = styled(Button)(() => ({
    height: isMobile ? 30 : 35,
    padding: isMobile ? '8px 16px' : '8px 16px',
    backgroundColor: '#FFFFF',
    borderRadius: 100,
    marginRight: 10,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'capitalize',
    border: '2px solid #000',
    fontWeight: '700',
    marginLeft: '10px',
    fontSize: isMobile ? '14px' : '16px',
  }))

  return (
    <>
      <Container
        container
        bgcolor={'#63EA71'}
        sx={{
          top: 0,
          zIndex: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          cursor: 'pointer',
          height: '45px',
        }}
        // sx={{ cursor: 'pointer' }}

        justifyContent={'center'}
        alignItems={'center'}
        onClick={onButtonClick}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
          onClick={() => window.open('https://arcade.apeterminal.io')}
        >
          <Typography
            fontSize={isMobile ? '16px' : '18px'}
            fontWeight={600}
            lineHeight={isMobile ? '21px' : '24px'}
            color="#000"
          >
            The Ape Arcade is live. Play now.
          </Typography>
        </Box>
        <IconButton onClick={onCloseClick}>
          <CloseIcon sx={{ cursor: 'pointer', color: '#000' }} />
        </IconButton>
      </Container>
    </>
  )
}

export default GiftCardBanner
