import React, { useEffect, useState } from 'react'
import { getChainIdFromName } from '../../utils'
import GeneralClaim from './GeneralClaim'
import InspectClaim from './InspectClaim'
import ChainChangeModal from '../../components/ChainChangeModal'
import { useAccount } from 'wagmi'
//
const ClaimWrapper = ({ idoData, project, closeClaimTab }: any) => {
  const { chain } = useAccount()

  const [showChainChangeModal, setShowChainChangeModal] = useState(false)

  let chainId = getChainIdFromName(idoData?.claimNetwork)

  const handleChainChange = () => {
    setShowChainChangeModal(true)
  }

  useEffect(() => {
    if (chain?.id !== chainId) {
      handleChainChange()
    } else {
      setShowChainChangeModal(false)
    }
  }, [chain])

  console.log('ClaimWrapper', { idoData, chainId, chain })
  return (
    <>
      {showChainChangeModal && (
        <ChainChangeModal
          open={showChainChangeModal}
          handleClose={() => {
            closeClaimTab()
            setShowChainChangeModal(false)
          }}
          chainId={getChainIdFromName(idoData?.claimNetwork as string)}
        />
      )}

      {chain?.id === chainId ? (
        idoData._id === '6554d8e0bf282065fe95f222' ? (
          <InspectClaim projectId={project._id} idoData={idoData} />
        ) : (
          <GeneralClaim projectId={project._id} idoData={idoData} />
        )
      ) : (
        <></>
      )}
    </>
  )
}

export default ClaimWrapper
