import DarkSwapIcon from '../../assets/d_swap.png'
import LightSwapIcon from '../../assets/l_swap.png'
import HomeIcon from '@mui/icons-material/Home'
import CreditCardIcon from '@mui/icons-material/CreditCard'

import { ReactComponent as LaunchpadIcon } from '../../assets/ape_app_icon.svg'
import { ReactComponent as PortfolioIcon } from '../../assets/portfolio_icon.svg'
import { ReactComponent as ApeAppIcon } from '../../assets/launchpad_icon.svg'

const navList = [
  {
    name: 'Launchpad',
    icon_light: LightSwapIcon,
    icon_dark: DarkSwapIcon,
    svg: LaunchpadIcon,
    href: '/',
    authorizationRequired: false,
    icon: HomeIcon,
    target: '_self',
  },
  {
    name: 'Portfolio',
    icon_light: LightSwapIcon,
    icon_dark: DarkSwapIcon,
    svg: PortfolioIcon,
    href: '/portfolio',
    authorizationRequired: true,
    icon: CreditCardIcon,
    target: '_self',
  },
  {
    name: 'Transparency',
    icon_light: LightSwapIcon,
    icon_dark: DarkSwapIcon,
    svg: ApeAppIcon,
    href: '/transparency',
    authorizationRequired: true,
    icon: ApeAppIcon,
    target: '_self',
  },
  // {
  //   name: 'Yield App',
  //   icon_light: LightSwapIcon,
  //   icon_dark: DarkSwapIcon,
  //   svg: ApeAppIcon,
  //   href: 'https://app.apeterminal.io',
  //   target: '_blank',
  //   authorizationRequired: true,
  //   icon: ApeAppIcon,
  // },
]

export default navList
