import { Button, Typography } from '@mui/material'

import { ConnectWalletArrow } from '../../assets/index'

const ConnectButton = ({
  text,
  onClick,
  disabled = false,
  id,
}: {
  text?: string
  onClick?: (e: any) => void
  disabled?: boolean
  id?: string
}) => {
  return (
    <>
      <style>
        {`
          .connect-button__decor--1,
          .connect-button__decor--2 {
            border-radius: 80px;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            pointer-events: none;
            user-select: none;
            opacity: 0;
          }

          .connect-button__decor--1 {
            width: 168px;
            height: 46px;
            border: 1px solid #2d2d2d;
            transition: all 0.3s 0.2s;
          }

          .connect-button__decor--2 {
            width: 174px;
            height: 52px;
            border: 1px solid #1e1e1e;
            transition: all 0.3s 0.1s;
          }

          .connect-button__decor--1::before,
          .connect-button__decor--1::after,
          .connect-button__decor--2::before,
          .connect-button__decor--2::after,
          .connect-button__icon-hover::before,
          .connect-button__icon-hover::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
          }

          .connect-button__decor--1::after,
          .connect-button__decor--2::after {
            background-color: black;
            border-radius: 80px;
            height: calc(100% - 1px);
            width: calc(100% - 1px);
          }

          .connect-button__icon-hover::after {
            background-color: black;
            border-radius: 50%;
            height: calc(100% - 1px);
            width: calc(100% - 1px);
          }

          .connect-button__decor--1::before {
            animation: connect-decor 3s linear infinite;
            background: linear-gradient(90deg, #63ea71 0%, rgba(99, 234, 113, 0) 30%);
            height: 46px;
            width: 168px;
          }

          .connect-button__decor--2::before {
            animation: connect-decor 3s linear 1s infinite;
            background: linear-gradient(90deg, #63ea71 0%, rgba(99, 234, 113, 0) 30%);
            width: 174px;
            height: 52px;
          }

          .connect-button__icon-hover::before {
            animation: connect-decor 3s linear infinite;
            background: linear-gradient(90deg, #63ea71 0%, rgba(99, 234, 113, 0) 30%);
            width: 22px;
            height: 22px;
          }

          .connect-button__icon-hover {
            width: 22px;
            height: 22px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #1d1f21;
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            top: 1px;
            left: 1px;
            transition: all 0.3s;
            opacity: 0;
            pointer-events: none;
            user-select: none;
          }

          @keyframes connect-decor {
            from {
              transform: translateX(-50%) translateY(-50%) rotate(0deg);
            }
            to {
              transform: translateX(-50%) translateY(-50%) rotate(360deg);
            }
          }
        `}
      </style>
      <div className="connect-button__wrapper" style={{ position: 'relative' }}>
        <Button
          sx={{
            backgroundColor: '#000000',
            border: '1px solid #FFFFFF33',
            borderRadius: '80px',
            padding: '8px 16px 8px 8px',
            height: '40px',
            width: '162px',
            alignSelf: 'center',
            position: 'relative',
            zIndex: 5,
            transition: 'all 0.3s',
            '&:hover': {
              border: '1px solid #434343',
            },
            '&:hover > .connect-button__text': {
              color: '#63EA71',
            },
            '&:hover + .connect-button__decor--2': {
              opacity: 1,
              transition: 'all 0.3s 0.2s',
            },
            '&:hover + .connect-button__decor--2 + .connect-button__decor--1': {
              opacity: 1,
              transition: 'all 0.3s 0.1s',
            },
            '&:hover > .connect-button__icon .connect-button__icon-normal': {
              opacity: 0,
            },
            '&:hover > .connect-button__icon .connect-button__icon-hover': {
              opacity: 1,
            },
          }}
          onClick={onClick}
          disabled={disabled}
          id={id}
        >
          <div
            className="connect-button__icon"
            style={{
              marginRight: '8px',
              width: '24px',
              height: '24px',
              position: 'relative',
            }}
          >
            <img
              src={ConnectWalletArrow}
              style={{
                width: '24px',
                height: '24px',
                transition: 'all 0.3s',
              }}
              alt="connect-wallet-icon"
              className="connect-button__icon-normal"
            />
            <div className="connect-button__icon-hover">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: 'relative', zIndex: '2' }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.93164 1.99108L8.09041 5.19999H0.20048V6.79999H8.09041L4.93164 10.0089L6.07189 11.1313L11.123 5.99999L6.07189 0.868652L4.93164 1.99108Z"
                  fill="#63EA71"
                />
              </svg>
            </div>
          </div>

          {text && (
            <Typography
              fontSize={15}
              fontWeight={400}
              lineHeight={'22.5px'}
              letterSpacing={'-0.01em'}
              color="rgba(255, 255, 255, 1)"
              textTransform={'capitalize'}
              className="connect-button__text"
              style={{ transition: 'all 0.3s' }}
            >
              {text}
            </Typography>
          )}
        </Button>
        <div className="connect-button__decor--2"></div>
        <div className="connect-button__decor--1"></div>
      </div>
    </>
  )
}

export default ConnectButton
