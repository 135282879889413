import { useEffect } from 'react'
import {
  Dialog,
  IconButton,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { LightCross, CWIcons, GridLines } from '../../assets'
import GreenButton from '../../components/GreenButton'
import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import { useAppSelector, useSignMessageAndRegisterAccount } from '../../hooks'
import OutlinedButton from '../../components/Buttons/OutlinedButton'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
}

const SignatureModal = ({ open, setOpen }: Props) => {
  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()
  const { loader, callSignTypedData } = useSignMessageAndRegisterAccount()
  const isDown600 = useMediaQuery('(max-width:600px)')

  const mainAppToken = useAppSelector(state => state.user.mainAppToken)

  useEffect(() => {
    if (mainAppToken) {
      setOpen(false)
    }
  }, [mainAppToken])

  const handleClose = () => {
    setOpen(false)
    disconnectWalletAndProfile()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '12px',
            maxWidth: '468px',
            width: '100%',
            height: '500px',
            borderRadius: '24px',
            border: '0.5px solid rgba(255, 255, 255, 0.16)',
            backgroundColor: '#0F1113',
            backgroundImage:
              'radial-gradient(69.23% 49.77% at 40.74% -11.21%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%)',
            position: 'relative',
          },
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '40%',
            backgroundImage: `url(${GridLines})`,
            backgroundRepeat: 'repeat',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            edge="end"
            onClick={handleClose}
            aria-label="close"
            sx={{
              backgroundColor: '#1D1F21',
              height: '40px',
              width: '40px',
              marginRight: '0px',
              border: '1px solid #1D1F21',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: '#0F1113',
                border: '1px solid #313335',
              },
            }}
          >
            <img src={LightCross} alt="close" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '20px',
            width: isDown600 ? '90%' : '70%',
            alignSelf: 'center',
            textAlign: 'center',
            position: 'relative',
            zIndex: 3,
          }}
        >
          <img
            src={CWIcons}
            alt="Chain Change"
            style={{
              width: '184px',
              height: '64px',
            }}
          />
          <Typography
            sx={{
              paddingTop: '24px',
              paddingBottom: '12px',
              fontSize: '32px',
              fontWeight: 600,
              color: '#FFFFFF',
              fontFamily: 'Hellix',
            }}
          >
            Connect Wallet
          </Typography>

          <Typography
            sx={{
              color: '#7E8180',
              fontSize: '16px',
              fontWeight: 400,
              fontFamily: 'Inter',
              marginBottom: '40px',
            }}
          >
            Signing in with your wallet is required to get the best experience.
          </Typography>

          <GreenButton
            text={'Sign in with wallet'}
            br="24px"
            fs={15}
            fw={400}
            fm="Inter"
            lh="22.5px"
            p="14px 32px"
            h="48px"
            w={'100%'}
            sx={{
              textTransform: 'none',
              mb: '16px',
            }}
            onClick={callSignTypedData}
          />

          <OutlinedButton
            onClick={handleClose}
            sx={{
              height: '48px',
              width: '100%',
              '&:hover': {
                border: '1px solid #313335',
              },
            }}
          >
            Do it later
          </OutlinedButton>
        </Box>
      </Dialog>
    </>
  )
}

export default SignatureModal
