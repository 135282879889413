import { Box, Typography, styled, useMediaQuery } from '@mui/material'
import { CompletedPattern, CopyClipIcon, GreenTick } from '../../assets'
import { BoxUnion } from '../../assets'
import CopyToClipboard from 'react-copy-to-clipboard'
import { setSnackbar } from '../../redux/slices/themeSlice'
import { useDispatch } from 'react-redux'
import { shortenAddress } from '../../utils'

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: '#7E8180',
  fontWeight: 400,
  lineHeight: '16.8px',
  fontFamily: 'Inter',
  textAlign: 'left',
  width: '100%',
}))

const StyledSubText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: '#B1B4B3',
  fontWeight: 500,
  lineHeight: '21px',
  fontFamily: 'Inter',
  textAlign: 'left',
  width: '100%',
  display: 'flex',
  lineBreak: 'anywhere',
}))

const CompletedBox = ({ address }: any) => {
  const dispatch = useDispatch()
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        flex: 1,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        textAlign={'center'}
        width={'100%'}
        padding={'16px'}
        paddingTop={'32px'}
      >
        <img
          src={isDown1000 ? CompletedPattern : GreenTick}
          alt="keep trying"
          style={{
            width: isDown1000 ? '208px' : '64px',
            height: isDown1000 ? '208px' : '64px',
          }}
        />
        <Typography
          pt={isDown1000 ? 0 : '32px'}
          marginTop={isDown1000 ? '-45px' : '0px'}
          pb={'16px'}
          fontFamily={'Hellix'}
          fontSize={32}
          fontWeight={600}
          lineHeight={'40px'}
          color={'#FFFFFF'}
          paddingX={'32px'}
        >
          Investment Confirmed
        </Typography>
        <Typography
          fontFamily={'Inter'}
          fontSize={16}
          fontWeight={400}
          lineHeight={'24px'}
          color={'#7E8180'}
          paddingX={isDown1000 ? '32px' : '22%'}
        >
          You’re in! You can receive your tokens after the TGE. Don’t forget to
          share & engage on socials.
        </Typography>
        <Box
          sx={{
            backgroundColor: '#0F1113',
            width: '100%',
            paddingX: '16px',
            paddingBottom: '16px',
            mt: isDown1000 ? '40px' : '26px',
            borderRadius: '8px',
          }}
        >
          <img
            src={BoxUnion}
            alt="box-union"
            style={{
              position: 'relative',
              top: '-2px',
            }}
          />
          {/* -----------------------------------1------------------------------ */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isDown1000 ? 'column' : 'row',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            <StyledText>You won</StyledText>
            <StyledSubText
              sx={{
                color: '#FF7722',
              }}
            >
              1 tickets
            </StyledSubText>
          </Box>

          {/* -----------------------------------2------------------------------ */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              pt: isDown1000 ? '16px' : '18px',
              alignItems: 'center',
              flexDirection: isDown1000 ? 'column' : 'row',
              gap: '4px',
            }}
          >
            <StyledText>Participated tickets</StyledText>
            <StyledSubText>1</StyledSubText>
          </Box>

          {/* -----------------------------------3------------------------------ */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isDown1000 ? 'column' : 'row',
              gap: '4px',
              pt: isDown1000 ? '16px' : '18px',
              alignItems: 'center',
            }}
          >
            <StyledText>Participated wallet</StyledText>
            <StyledSubText>{shortenAddress(address)}</StyledSubText>
          </Box>

          {/* -----------------------------------4------------------------------ */}
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isDown1000 ? 'column' : 'row',
              gap: '4px',
              pt: isDown1000 ? '16px' : '18px',
              alignItems: 'center',
            }}
          >
            <StyledText>Token vesting</StyledText>
            <StyledSubText>
              10% at TGE, 3 month cliff, then 18 months leaner vesting
            </StyledSubText>
          </Box> */}

          {/* -----------------------------------5------------------------------ */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isDown1000 ? 'column' : 'row',
              gap: '4px',
              pt: isDown1000 ? '16px' : '18px',
              alignItems: 'center',
            }}
          >
            <StyledText>Blockchain Network</StyledText>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#B1B4B3',
                  fontWeight: 500,
                  lineHeight: '21px',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  width: 'fit-content',
                  backgroundColor: '#1D1F21',
                  padding: '4px 10px',
                  borderRadius: '60px',
                }}
              >
                BNB Chain
              </Typography>
            </Box>
          </Box>

          {/* -----------------------------------6------------------------------ */}
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isDown1000 ? 'column' : 'row',
              gap: '4px',
              pt: isDown1000 ? '16px' : '18px',
              alignItems: 'center',
            }}
          >
            <StyledText>Transaction TX</StyledText>
            <StyledSubText>
              d239qw41acx24wq...A213
              <span
                style={{
                  height: '21px',
                  marginLeft: '6px',
                  cursor: 'pointer',
                }}
              >
                <CopyToClipboard
                  text={'d239qw41acx24wq...A213'}
                  onCopy={() => {
                    dispatch(
                      setSnackbar({
                        open: true,
                        severity: 'success',
                        message: 'Copied',
                      }),
                    )
                  }}
                >
                  <img
                    src={CopyClipIcon}
                    alt="copy-clip"
                    style={{
                      marginTop: '2px',
                    }}
                  />
                </CopyToClipboard>
              </span>
            </StyledSubText>
          </Box> */}
        </Box>
      </Box>
    </Box>
  )
}

export default CompletedBox
