import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import riveWASMResource from '@rive-app/canvas/rive.wasm'
import Rive, {
  Layout,
  Fit,
  Alignment,
  RuntimeLoader,
} from '@rive-app/react-canvas'
import { Helmet } from 'react-helmet'

import Motion from '../../components/Motion/Motion'

import {
  NewHomepageBg,
  NewHomepageBgMobile,
  HeroBackgroundDecor,
  HeroBackgroundDecorMobile,
} from '../../assets'
import { ReactComponent as TelegramNew } from '../../assets/telegram_new.svg'
import { ReactComponent as TwitterNew } from '../../assets/twitter_new.svg'

import UpcomingLaunch from './UpcomingLaunch'
import {
  LaunchType,
  useFetchAllLaunchesQuery,
  useFetchAllProjectsQuery,
  useFetchLaunchpadPublicStatsQuery,
} from '../../services/modules/project'
import { useLocation, useNavigate } from 'react-router-dom'
// import Lottie from 'lottie-react'
// import LogoAnimation from '../../animations/logo loading white.json'
import { scrollToTop } from '../../utils'
// import OurStatistics from './OurStatistics'
import ParticipationSection from './ParticipationSection'
import HeroBanner from './HeroBanner'
import CompletedSales from './CompletedSales'
import LinkButtons from './LinkButtons'
import AnimatedStatistics from './AnimatedStatistics'
import { height, positions } from '@mui/system'

const RevampedLaunchpad: React.FC = () => {
  RuntimeLoader.setWasmUrl(riveWASMResource)

  const { data } = useFetchAllProjectsQuery()

  const navigate = useNavigate()

  const location = useLocation()

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  const isDown360 = useMediaQuery('(max-width:360px)')
  const isDown1220 = useMediaQuery('(max-width:1220px)')
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown850 = useMediaQuery('(max-width:850px)')

  const STATE_MACHINE_NAME = 'Interaction'

  const Links = [
    { icon: TelegramNew, link: 'https://t.me/ApeTerminalChat' },
    { icon: TwitterNew, link: 'https://twitter.com/apeterminal' },
  ]

  const {
    data: allLaunchesData,
    isLoading: allLaunchesLoading,
    isError: allLaunchesError,
  } = useFetchAllLaunchesQuery()
  const {
    data: launchpadStats,
    isLoading: launchpadStatsLoading,
    isError: launchpadStatsError,
  } = useFetchLaunchpadPublicStatsQuery()

  console.log('launchpadStats', launchpadStats)
  useEffect(() => {
    scrollToTop()
  }, [location.pathname])

  useEffect(() => {
    if (!allLaunchesLoading) document?.body?.classList?.remove('no-scroll')
  }, [allLaunchesLoading])

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href={riveWASMResource}
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="rive/grid_header_ape.riv"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="rive/ape_element_01-new.riv"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="rive/ape_element_02-new.riv"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="rive/ape_element_03-new.riv"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="image"
          href={isDown600 ? NewHomepageBgMobile : NewHomepageBg}
        />
        <link
          rel="preload"
          as="image"
          href={isDown600 ? HeroBackgroundDecorMobile : HeroBackgroundDecor}
        />
      </Helmet>

      <Box bgcolor={'#000'} display={'flex'} flexDirection={'column'}>
        {/* {allLaunchesLoading && (
        <Box
          sx={{
            backgroundColor: '#000',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Lottie
            animationData={LogoAnimation}
            loop={true}
            style={{
              height: '20vh',
            }}
          />
        </Box>
      )} */}
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <div className="rive-lines">
            <Motion
              threshold={0.1}
              duration={0.8}
              delay={0.2}
              style={{
                display: 'block',
                height: '100%',
                width: '100%',
              }}
            >
              <div
                className="rive-lines__inner"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  pointerEvents: 'none',
                  userSelect: 'none',
                  zIndex: 1,
                  opacity: 0.6,
                  aspectRatio: isDown600 ? '16/30' : '16/10',
                }}
              >
                <Rive
                  src="rive/grid_header_ape.riv"
                  stateMachines={STATE_MACHINE_NAME}
                  layout={
                    new Layout({
                      fit: isDown600 ? Fit.Cover : Fit.Contain,
                      alignment: Alignment.TopCenter,
                    })
                  }
                />
              </div>
            </Motion>
          </div>

          <Box
            sx={{
              width: '100%',
              zIndex: 1,
              fontSize: 0,
            }}
          >
            <img
              src={isDown600 ? NewHomepageBgMobile : NewHomepageBg}
              style={{
                width: '100vw',
                height: '100%',
                pointerEvents: 'none',
                userSelect: 'none',
                aspectRatio: isDown600 ? '9/16' : '1278/749',
              }}
              alt="background"
            />

            <Motion
              threshold={0.1}
              duration={0.8}
              style={{
                display: 'block',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <div
                className="hero-background-decor"
                style={{
                  width: '100%',
                  position: 'absolute',
                  left: '0',
                  top: isDown600 ? '27%' : '26%',
                  pointerEvents: 'none',
                  zIndex: 0,
                  userSelect: 'none',
                }}
              >
                <img
                  src={
                    isDown600 ? HeroBackgroundDecorMobile : HeroBackgroundDecor
                  }
                  style={{
                    width: '100vw',
                    height: '100%',
                    pointerEvents: 'none',
                  }}
                  alt="background-decor"
                  // className="animate-blinking"
                />
              </div>
            </Motion>
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              zIndex: 1,
              position: 'absolute',
              top: isDown600 ? '27%' : '32%',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              px={isDown360 ? '20px' : '36px'}
              // mb={'112px'}
            >
              <Typography
                fontSize={isMobile ? 42 : 96}
                fontWeight={600}
                lineHeight={'100%'}
                color="#fff"
                letterSpacing={'-0.02em'}
                textAlign={'center'}
                fontFamily={'Hellix'}
                sx={{
                  alignSelf: 'center',
                }}
              >
                <Motion
                  from={{ opacity: 1, filter: 'blur(5px)' }}
                  to={{ filter: 'blur(0px)' }}
                  threshold={0.1}
                  duration={0.5}
                  delay={0.05}
                >
                  <span
                    style={{
                      background:
                        'radial-gradient(77.99% 64.58% at 49.93% 17.71%, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%)',
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    Most Liquid
                  </span>
                </Motion>

                <br />

                <Motion
                  from={{ opacity: 1, filter: 'blur(5px)' }}
                  to={{ filter: 'blur(0px)' }}
                  threshold={0.1}
                  delay={0.15}
                  duration={0.5}
                >
                  <span
                    style={{
                      background:
                        'radial-gradient(77.99% 64.58% at 49.93% 17.71%, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%)',
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    Primary Market
                  </span>
                </Motion>
              </Typography>

              <Motion
                from={{ opacity: 0, y: 5, filter: 'blur(5px)' }}
                to={{ filter: 'blur(0px)' }}
                threshold={0.1}
                delay={0.15}
              >
                <Typography
                  fontSize={isMobile ? '17px' : '20px'}
                  fontWeight={400}
                  lineHeight={'27px'}
                  color="#B1B4B3"
                  letterSpacing={'0px'}
                  mt={2}
                  textAlign={'center'}
                  fontFamily={'Inter'}
                  // px={isDown360 ? '0%' : isDown767 ? '5%' : '0%'}
                  mb={'32px'}
                >
                  {isDown767 ? (
                    'Claim first access to the most important tokens in crypto.'
                  ) : (
                    <>
                      Claim first access to the most <br /> important tokens in
                      crypto.
                    </>
                  )}
                </Typography>
              </Motion>

              <Motion
                from={{ opacity: 0, y: 5, filter: 'blur(5px)' }}
                to={{ filter: 'blur(0px)' }}
                threshold={0.1}
                delay={0.2}
              >
                <LinkButtons
                  linksArray={Links}
                  width={'140px'}
                  iconWidth={'64px'}
                />
              </Motion>
            </Box>
          </Box>
        </Box>
        <Box
          maxWidth={'1200px'}
          width={isDown1220 ? '90%' : '100%'}
          alignSelf={'center'}
          display={'flex'}
          flexDirection={'column'}
          position={'relative'}
          sx={{
            marginTop: isDown600
              ? '-25%'
              : isDown850
              ? '-4%'
              : isDown1000
              ? '-8%'
              : '-12%',
            // isDown360 ? '-40%' : isDown767 ? '-45%' : '-12%',
          }}
        >
          <AnimatedStatistics launchpadStats={launchpadStats} />
          <UpcomingLaunch allLaunchesData={allLaunchesData || []} />
          {/* <OurStatistics launchpadStats={launchpadStats} /> */}
          <CompletedSales
            tokenSales={
              allLaunchesData?.filter(
                (launch: any) => launch.type === 'completed',
              ) || []
            }
          />
          <ParticipationSection />
          <HeroBanner />
        </Box>
      </Box>
    </>
  )
}

export default RevampedLaunchpad
