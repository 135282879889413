import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useUpdateSolanaAddressInParticipationMutation } from '../../services/modules/participations'
import SwitchToBSC from '../../pages/RevampedOverview/SwitchToBSC'
import RenderLost from './RenderLost'
import RenderWonTicketAndKYCPhase from './RenderWonTicketAndKYCPhase'
import RenderReceipt from './RenderReceipt'
import RenderSolanaAddress from './RenderSolanaAddress'
import RenderContainer from './RenderContainer'
import ChainSwitchBSC from './ChainSwitchBSC'
import InvestmentBox from './InvestmentBox'
import { useAccount } from 'wagmi'

const RenderInvestmentPhase = ({
  userInvested,
  _userParticipation,
  user,
  idoData,
  totalInvested,
  multiplierData,
  score,
  totalParticipants,
  totalAssetsConnected,
  onInvested,
}: any) => {
  const [solanaAddressUpdated, setSolanaAddressUpdated] = useState(false)

  const { chain } = useAccount()

  const [
    updateSolanaAddressInParticipation,
    {
      data: solanaAddressInParticipationData,
      isSuccess: isSolanaAddressInParticipationSuccess,
      error: solanaAddressInParticipationError,
    },
  ] = useUpdateSolanaAddressInParticipationMutation()

  useEffect(() => {
    if (solanaAddressInParticipationData) {
      setSolanaAddressUpdated(true)
    }
  }, [solanaAddressInParticipationData])

  const renderInvestmentPhase = () => {
    if (userInvested && _userParticipation?.isWinner) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderReceipt
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="won"
        />
      )
    } else if (_userParticipation?.isWinner && user.kycStatus !== 'approved') {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderWonTicketAndKYCPhase
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              user={user}
            />
          }
          type="won"
        />
      )
    } else if (
      !solanaAddressUpdated &&
      _userParticipation &&
      _userParticipation?.isWinner &&
      !_userParticipation?.solanaAddress &&
      (idoData._id === '65967ae64492fe630b2a1c63' ||
        idoData._id === '65ba5079ed723a4f03528cfe' ||
        idoData._id === '65fda62c90a71328b0e712f1') // for mixMob
    ) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderSolanaAddress
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              userParticipation={_userParticipation}
              updateSolanaAddressInParticipation={
                updateSolanaAddressInParticipation
              }
            />
          }
          type="registered"
        />
      )
    } else if (_userParticipation?.isWinner && !userInvested) {
      if (chain?.id !== 56) {
        return (
          <RenderContainer
            score={score}
            totalParticipants={totalParticipants}
            totalAssetsConnected={totalAssetsConnected}
            multiplierData={multiplierData}
            idoData={idoData}
            sideComponent={<ChainSwitchBSC />}
            type="won"
          />
        )
      }

      return (
        <RenderContainer
          type={'won'}
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <InvestmentBox idoData={idoData} onInvested={onInvested} />
          }
        />
      )
    } else if (userInvested && _userParticipation?.isWinner) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderReceipt
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="won"
        />
      )
    } else if (
      _userParticipation?.isWinner &&
      totalInvested >= idoData.totalInvestors
    ) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderLost
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="won"
        />
      )
    } else if (!_userParticipation?.isWinner) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderLost
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
            />
          }
          type="registered"
        />
      )
    }
  }
  return <>{renderInvestmentPhase()}</>
}

export default RenderInvestmentPhase
