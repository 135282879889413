import { GiftICon } from '../../assets'
import { Grid, Typography, styled } from '@mui/material'

const GiftBoxContainer = styled(Grid)(() => ({
  height: 40,
  padding: '10px 16px',
  backgroundColor: 'rgba(15, 17, 19, 1)',
  width: '74px',
  borderRadius: '24px',
}))

const GiftBox = ({ sx, mobile }: any) => {
  return (
    <GiftBoxContainer
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      sx={{
        ...sx,
      }}
    >
      <img
        src={GiftICon}
        style={{ marginRight: '8px', height: '16px' }}
        alt="gift icon"
      />

      {mobile && (
        <Typography
          marginRight={'8px'}
          fontFamily={'Inter'}
          fontWeight={600}
          fontSize={'14px'}
          lineHeight={'22px'}
          color={'#63EA71'}
        >
          Gift Balance
        </Typography>
      )}
      <Typography
        fontFamily={'Inter'}
        fontWeight={500}
        fontSize={'14px'}
        lineHeight={'22px'}
      >
        $25
      </Typography>
    </GiftBoxContainer>
  )
}

export default GiftBox
