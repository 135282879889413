import { Grid, styled } from '@mui/material'

export const Container = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  //background: theme.palette.background.paper,
  //borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  //height: '120px',
  //boxShadow: '-1px 12px 11px 0px rgba(0,0,0,0.34)',

  zIndex: 200,
}))
