import { InputIcon } from '../../assets'
import {
  Box,
  InputAdornment,
  InputLabel,
  TextField,
  Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface StyleProps {
  bgColor: string
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    border: 'none',
    backgroundColor: ({ bgColor }) => bgColor,
    borderRadius: '12px',
  },
}))

const InputWithLabel = ({
  label,
  labelColor = '#fff',
  value,
  onChange,
  mb = 1,
  placeholder,
  bgColor = '#141414',
  fs,
  fw,
  lh,
  color,
  fm,
  disabled,
  endAdornmentIcon,
  w,
  br,
  p,
  h,
  onAdornmentClick,
  px,
  sx,
  caretColor,
}: {
  label?: string
  labelColor?: string
  value: string
  mb?: number
  placeholder?: string
  bgColor?: string
  fs?: number | string
  fw?: number
  lh?: string
  color?: string
  fm?: string
  onChange: (value: string) => void
  disabled?: boolean
  endAdornmentIcon?: React.ReactNode
  w?: string
  br?: string
  p?: string
  h?: string
  onAdornmentClick?: () => void
  px?: string
  sx?: any
  caretColor?: string
}) => {
  const classes = useStyles({
    bgColor,
  })
  // make Mui input with label
  return (
    <Box
      mb={mb}
      width={w ? w : 'auto'}
      sx={{
        ...sx,
      }}
    >
      {label && (
        <InputLabel
          color="primary"
          sx={{
            mb: 1,
          }}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        variant="outlined"
        color="primary"
        autoComplete="off"
        placeholder={placeholder}
        disabled={disabled}
        sx={{
          // font size
          '& .MuiInputBase-input': {
            fontSize: fs ? fs : 16,
            fontWeight: fw ? fw : 500,
            lineHeight: lh ? lh : '24px',
            color: color ? color : '#fff',
            fontFamily: fm ? fm : 'Hellix',
            caretColor: caretColor || '#6FEB7C',

            borderRadius: br ? br : 'auto',
            px: px ? px : 'auto',

            // hover
            '&:hover': {
              color: color ? color : '#fff',
            },
          },

          // placeholder color
          '& .MuiInputBase-input::placeholder': {
            color: '#7E8180',
          },

          '& .MuiOutlinedInput-root': {
            borderRadius: br ? br : '12px',
            height: h ? h : 'auto',
            width: w ? w : '100%',

            '& fieldset': {
              borderColor: 'transparent',
              //backgroundColor: '#141414',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
              // backgroundColor: '#141414',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },

          // disabled styles
          '& .Mui-disabled': {
            color: '#7E8180',
            border: 'none',
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#141414',
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
          },
        }}
        InputProps={{
          startAdornment: endAdornmentIcon ? null : (
            <InputAdornment
              position="start"
              sx={{
                mr: '4px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={onAdornmentClick}
              // onClick={e => {
              //   e.currentTarget.parentElement?.querySelector('input')?.focus()
              // }}
            >
              <img src={InputIcon} alt="input icon" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment onClick={onAdornmentClick} position="start">
              {endAdornmentIcon}
            </InputAdornment>
          ),
          classes: {
            root: classes.root,
          },
        }}
        value={value}
        onChange={e => onChange(e.target.value)}
        fullWidth
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onAdornmentClick && onAdornmentClick()
          }
        }}
      />
    </Box>
  )
}

export default InputWithLabel
