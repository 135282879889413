import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { StyledSubText, StyledText } from '.'
import StatsBox from './StatsBox'
import CompletedBox from './CompletedBox'
import { useAccount } from 'wagmi'

const RenderReceipt = ({}: any) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')

  const { address } = useAccount()
  return <CompletedBox address={address} />
}

export default RenderReceipt
