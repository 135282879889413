import { Box, Typography, useMediaQuery } from '@mui/material'
import {
  ChangeWallet,
  ChangeWalletPattern,
  FilledBlackArrow,
} from '../../assets'
import GreenButton from '../../components/GreenButton'
import React from 'react'
import { chainChangeRequest } from '../../utils'

const ChainSwitchBSC = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')

  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        flex: 1,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: isDown1000 ? '32px' : '0px',
        width: '100%',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        textAlign={'center'}
        paddingX={isDown1000 ? '32px' : '10%'}
        width={'100%'}
      >
        <img
          src={isDown1000 ? ChangeWalletPattern : ChangeWallet}
          alt="Change Wallet"
          style={{
            width: isDown1000 ? '208px' : '64px',
            height: isDown1000 ? '208px' : '64px',
          }}
        />

        <Typography
          pt={isDown1000 ? 0 : '32px'}
          marginTop={isDown1000 ? '-45px' : '0px'}
          pb={isDown1000 ? 0 : '24px'}
          fontFamily={'Hellix'}
          fontSize={32}
          fontWeight={600}
          lineHeight={'40px'}
          color={'#FFFFFF'}
        >
          Change your Wallet to the BSC Network
        </Typography>

        <GreenButton
          fs={15}
          lh="22.5px"
          fm="Inter"
          fw={400}
          br="24px"
          text={'Switch'}
          mt={5}
          onClick={() => {
            chainChangeRequest('0x38', () => {})
          }}
          w="150px"
          IconStart={() => (
            <img src={FilledBlackArrow} alt="Filled Black Arrow" />
          )}
        />
      </Box>
    </Box>
  )
}

export default ChainSwitchBSC
