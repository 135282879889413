import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import './web3AuthModal.css'
import { useDispatch } from 'react-redux'

import { OpenloginUserInfo } from '@toruslabs/openlogin-utils'
import { useAppSelector } from '../../hooks/'
import GiftCardBanner from '../../components//GiftCardBanner'
import { useAccount } from 'wagmi'

interface IWeb3AuthModalButton {
  text?: boolean
  roundedButton?: boolean
  displayBanner?: any
}

const Web3AuthModalButton: React.FC<IWeb3AuthModalButton> = ({
  text,
  roundedButton,
  displayBanner,
}) => {
  const SignUpButton = styled(Button)(() => ({
    height: 40,
    padding: '12px 20px',
    backgroundColor: '#FFFFF',
    borderRadius: 100,
    marginRight: 10,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'capitalize',
    border: '2px solid #000',
    fontWeight: '700',
    marginLeft: '10px',
    fontSize: '20px',
  }))

  const { web3AuthInstance } = useAppSelector(state => state.app)

  const [user, setUser] = useState<Partial<OpenloginUserInfo> | undefined>()

  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [showGiftBanner, setShowGiftBanner] = useState<boolean>(false)

  const { address } = useAccount()

  const onButtonClick = useCallback(async () => {
    console.log(web3AuthInstance, 'web3AutInstance')

    if (web3AuthInstance) {
      try {
        const web3authProvider = await web3AuthInstance?.connect()
      } catch (e) {
        console.error(e, 'from web3Auth OnButtonClick')
      }
    }
  }, [web3AuthInstance, dispatch])

  const onCloseClick = (e: any) => {
    e.stopPropagation()
    setShowGiftBanner(false)
  }

  useEffect(() => {
    displayBanner && displayBanner(true)
  }, [address, showGiftBanner, roundedButton])

  return (
    <GiftCardBanner
      onCloseClick={onCloseClick}
      onButtonClick={onButtonClick}
    ></GiftCardBanner>
  )

  if (roundedButton)
    if (!address && showGiftBanner) {
      return (
        <GiftCardBanner
          onCloseClick={onCloseClick}
          onButtonClick={onButtonClick}
        ></GiftCardBanner>
      )
    } else {
      return <></>
    }
  if (text)
    return (
      <Typography
        fontSize={15}
        fontWeight={600}
        lineHeight={'24px'}
        color="#FFFFFF"
        onClick={onButtonClick}
        sx={{ fontSize: '16px !important' }}
      >
        Login
      </Typography>
    )
  return (
    <Button
      sx={{
        mr: 1,
        backgroundColor: '#fff',
        border: '1px solid #000000',
        borderRadius: '0px',
        height: '40px',

        '&:hover': {
          backgroundColor: '#fff',
        },
      }}
      onClick={onButtonClick}
    >
      <Typography
        color={'#000'}
        fontFamily={'Inter'}
        fontSize={16}
        lineHeight={'20px'}
        fontWeight={500}
        textTransform={'capitalize'}
      >
        Login
      </Typography>
    </Button>
  )
}

export default Web3AuthModalButton
