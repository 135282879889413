import { useRegisterUserAccountMutation } from '../services/modules/users'
import useDisconnectWalletAndProfile from './useDisconnectWalletAndEmail'
import { useEffect, useState } from 'react'
import { useAccount, useSignMessage, useSignTypedData } from 'wagmi'

const useSignMessageAndRegisterAccount = () => {
  const { address, chain } = useAccount()

  const [signatureFetched, setSignatureFetched] = useState(false)

  const { signMessageAsync } = useSignMessage()

  const [loader, setLoader] = useState(false)

  const [userRejectedSignature, setUserRejectedSignature] = useState(false)

  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()

  const [
    registerAccount,
    { isLoading: isLoadingRegisteration, data: registerAccountData },
  ] = useRegisterUserAccountMutation()

  const callRegisterAccount = async (address: string, signature: string) => {
    try {
      const response = await registerAccount({
        address,
        signature,
        chainId: chain?.id || 1,
      })
      // setSignatureFetched(true)
      // window.location.reload()
      return true
    } catch (error) {
      console.error('could not register account because:', error)
      return false
    }
  }

  const getUserSignedMessage = async () => {
    const signMessage = await signMessageAsync({
      message: '0xjskdgnkvisgrohnbhgsmsowhe',
    })

    return signMessage
  }

  // sign message from user with mail data

  //1. create types

  const types = {
    Mail: [
      { name: 'message', type: 'string' },
      { name: 'uri', type: 'string' },
      // { name: 'version', type: 'string' },
      // { name: 'chainId', type: 'string' },
      { name: 'contents', type: 'string' },
    ],
  } as const

  //2. create message
  const message = {
    contents: 'Sign in',
    message: 'Sign in with your wallet',
    uri: 'https://apeterminal.io/',
    // chainId: String(chain?.id || '1'),
    // version: '1.0',
  } as const

  //3. sign message function
  const {
    data: signature,
    isError,
    isPending: isLoadingSignature,

    error,
    isSuccess: signatureFetchedSuccess,
    signTypedData,
  } = useSignTypedData()

  useEffect(() => {
    if (signatureFetchedSuccess && signature) {
      console.log('🚀 ~ signature:', signature)
      if (signature && address) {
        callRegisterAccount(address, signature)
      }
    }
  }, [signatureFetchedSuccess, signature])

  const callSignTypedData = async () => {
    signTypedData({
      domain: {
        name: 'Ape Terminal Launchpad',
        // version: '1',
        // chainId: chain?.id,
        // verifyingContract: '0x2Fb52A9479C2eefE76D8e57A494C19826BbB74E9',
      },
      message,
      primaryType: 'Mail',
      types,
    })
  }

  useEffect(() => {
    if (isLoadingRegisteration || isLoadingSignature) setLoader(true)
    else setLoader(false)
  }, [isLoadingRegisteration || isLoadingSignature])

  return {
    loader,
    callRegisterAccount,
    signTypedData,
    callSignTypedData,
    signatureFetched,
    userRejectedSignature,
  }
}

export default useSignMessageAndRegisterAccount
