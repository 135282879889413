import React, { FC } from 'react'
import Router from '../../components//Router'

import {
  arbitrum,
  bsc,
  goerli,
  mainnet,
  avalanche,
  sepolia,
  polygon,
  base,
} from 'viem/chains'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { http, createConfig, WagmiProvider } from 'wagmi'
import { walletConnect, injected, coinbaseWallet } from 'wagmi/connectors'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      staleTime: 1 * 1000 * 60, // 1 Min
    },
  },
})

const projectId = '7236c984a38479e85f91b945bd9076a8'

const metadata = {
  name: 'Ape Launchpad',
  description:
    'The better way to ape. Exclusive access to the best projects & high-yield tech.',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const wagmiConfig = defaultWagmiConfig({
  chains: [mainnet, bsc, arbitrum, avalanche, polygon, base],
  projectId,
  metadata,
  // transports: {
  //   [mainnet.id]: http(),
  //   [goerli.id]: http(),
  //   [bsc.id]: http(),
  //   [arbitrum.id]: http(),
  //   [avalanche.id]: http(),
  //   [sepolia.id]: http(),
  // },
  // connectors: [
  //   walletConnect({ projectId, metadata, showQrModal: false }),
  //   injected({ shimDisconnect: true }),
  //   coinbaseWallet({
  //     appName: metadata.name,
  //     appLogoUrl: metadata.icons[0],
  //   }),
  // ],
})

createWeb3Modal({
  wagmiConfig,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
})

const Web3Provider = () => {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default Web3Provider
