import React from 'react'
import { Box, Modal, Typography, IconButton } from '@mui/material'
import ModalBody from '../ModalBody'
import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import { GridLines, LightCross, Disconnect } from '../../assets'
import OutlinedButton from '../../components/Buttons/OutlinedButton'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
}

const DisconnectWalletModal = ({ open, setOpen }: any) => {
  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()

  return (
    <Modal
      open={open}
      onClose={(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        reason?: 'backdropClick' | 'escapeKeyDown',
      ) => {
        if (reason === 'backdropClick') {
          e.stopPropagation()
          setOpen(false)
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: '#000000',
            opacity: 0.85,
          },
        },
      }}
    >
      <>
        <ModalBody
          sx={{
            padding: '12px',
            maxWidth: '468px',
            width: '100%',
            // height: '250px',
            borderRadius: '24px',
            border: '0.5px solid rgba(255, 255, 255, 0.16)',
            backgroundColor: '#0F1113',
            backgroundImage:
              'radial-gradient(69.23% 49.77% at 40.74% -11.21%, rgba(200, 254, 11, 0.08) 0%, rgba(200, 254, 11, 0) 100%)',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '40%',
              backgroundImage: `url(${GridLines})`,
              backgroundRepeat: 'repeat',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              edge="end"
              onClick={e => {
                setOpen(false)
                e.stopPropagation()
              }}
              aria-label="close"
              sx={{
                backgroundColor: '#1D1F21',
                height: '40px',
                width: '40px',
                marginRight: '0px',
                border: '1px solid #1D1F21',
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: '#0F1113',
                  border: '1px solid #313335',
                },
              }}
            >
              <img src={LightCross} alt="close" />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '20px',
              height: '100%',
              alignSelf: 'center',
              textAlign: 'center',
              position: 'relative',
              zIndex: 3,
            }}
          >
            <img
              src={Disconnect}
              alt="Disconnect"
              style={{
                width: '64px',
                height: '64px',
              }}
            />
            <Typography
              sx={{
                paddingTop: '24px',
                paddingBottom: '12px',
                fontSize: '32px',
                fontWeight: 600,
                color: '#FFFFFF',
                fontFamily: 'Hellix',
              }}
            >
              Disconnect Wallet
            </Typography>

            <Typography
              sx={{
                color: '#7E8180',
                fontSize: '16px',
                fontWeight: 400,
                fontFamily: 'Inter',
                marginBottom: '24px',
                textAlign: 'center',
              }}
            >
              Are you sure you want to disconnect <br /> your wallet?
            </Typography>

            <OutlinedButton
              onClick={() => disconnectWalletAndProfile()}
              sx={{
                height: '48px',
                width: '60%',
                '&:hover': {
                  border: '1px solid #313335',
                },
              }}
            >
              Disconnect
            </OutlinedButton>
          </Box>
        </ModalBody>
      </>
    </Modal>
  )
}

export default DisconnectWalletModal
