import { Box, Link } from '@mui/material'

const LinkButtons = ({
  linksArray,
  width,
  iconWidth,
}: {
  linksArray: { link: string; icon: any }[]
  width?: string
  iconWidth?: string
}) => (
  <Box
    sx={{
      display: 'flex',
      gap: '4px',
      background:
        'linear-gradient(180deg, rgba(56, 56, 56, 0.47) 0%, #1C1C1C 100%)',
      boxShadow:
        '0px 1px 2px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.12)',
      borderRadius: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px',
      height: '48px',
      width: width,
    }}
  >
    {linksArray.map((link, index) => (
      <Link
        key={index}
        sx={{
          color: '#C8CBCA',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '32px',
          backgroundColor: '#000000',
          height: '40px',
          width: iconWidth,
          '&:hover': { color: '#ffffff' },
        }}
        href={link.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <link.icon className="icon_svg orange_hover_svg" />
      </Link>
    ))}
  </Box>
)
export default LinkButtons
