import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledSubText, StyledText } from '.'
import StatsBox from './StatsBox'
import CompletedBox from './CompletedBox'
import { useAccount, useSignMessage } from 'wagmi'
import WalletBox from './WalletBox'
import { useUpdateSolanaAddressInParticipationMutation } from '../../services/modules/participations'
import { isValidSolanaAddress } from '../../utils'
import { useAppDispatch } from '../../hooks'
import { setSnackbar } from '../../redux/slices/themeSlice'

const RenderSolanaAddress = ({
  idoData,
  score,
  totalParticipants,
  totalAssetsConnected,
  multiplierData,
  userParticipation,
  updateSolanaAddressInParticipation,
}: any) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')

  const { address } = useAccount()

  const {
    data: signMessageData,
    error,
    isPending: isLoading,
    signMessage,
    variables,
  } = useSignMessage()

  const [solanaAddress, setSolanaAddress] = useState('')

  console.log('error', error)

  const dispatch = useAppDispatch()

  console.log('signMessageData', signMessageData)

  useEffect(() => {
    if (signMessageData) {
      console.log('signMessageData api called', signMessageData)
      updateSolanaAddressInParticipation({
        participationId: userParticipation?._id || '',
        solanaAddress,
        signature: signMessageData,
      })
    }
  }, [signMessageData])

  const onClick = () => {
    // check if solana address is valid
    // if valid then call the contract

    console.log('solanaAddress valid', solanaAddress)
    if (isValidSolanaAddress(solanaAddress)) {
      console.log('solanaAddress valid', solanaAddress)
      signMessage({
        message: solanaAddress,
      })
    } else {
      dispatch(
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Invalid Solana Address',
        }),
      )
    }
  }

  return (
    <WalletBox
      onSubmit={onClick}
      solanaAddress={solanaAddress}
      setSolanaAddress={setSolanaAddress}
    />
  )
}

export default RenderSolanaAddress
