import { Box, Typography, useMediaQuery } from '@mui/material'
import {
  TransparencyOne,
  TransparencyTwo,
  TransparencyThree,
  TransparencyFour,
} from '../../assets'

import Motion from '../../components/Motion/Motion'

interface FAQBoxProps {
  boxId: number
}

const faqs = [
  {
    icon: TransparencyOne,
    title: 'Project Criteria',
    titleColor: '#FFFFFF',
    description:
      'Core elements for project selection are user base, narrative economics, liquidity plans, and marketing strategy. How well a project matches these factors will be clarified ahead of its IDO.',
  },
  {
    icon: TransparencyTwo,
    title: 'Transparency',
    titleColor: '#FFFFFF',
    description:
      'VRF-based selection makes the IDO process transparent and safe from exploits.',
  },
  {
    icon: TransparencyThree,
    title: 'Winners',
    titleColor: '#FFFFFF',
    description:
      'Winner lists for each IDO will be published and will remain visible on this page for referencing in the future.',
  },
  {
    icon: TransparencyFour,
    title: 'Compliance',
    titleColor: '#FFFFFF',
    description:
      'While users don’t need to KYC for applying to an IDO, they must pass it to contribute if they win. If users win an allocation but fail to contribute, their allocation is backstopped by company treasury to ensure the IDO is completed.',
  },
]

const FAQBox: React.FC<FAQBoxProps> = ({ boxId }) => {
  const isDown600 = useMediaQuery('(max-width:600px)')
  return (
    <Motion
      from={{ y: 10, filter: 'blur(5px)' }}
      to={{ filter: 'blur(0px)' }}
      delay={(0.05 * boxId) as number}
      threshold={0.2}
      style={{
        display: 'block',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'left',
          gap: '12px',
          flexDirection: 'column',
          borderRadius: '14px',
          backgroundColor: '#0F1113',
          border: '0.5px solid rgba(255, 255, 255, 0.16)',
          padding: isDown600 ? '16px' : '24px',
          height: '100%',
        }}
      >
        <Box display={'flex'} alignItems={'center'} gap={'12px'}>
          <img
            src={faqs[boxId as number].icon}
            alt="icon"
            height={'24px'}
            width={'24px'}
          />
          <Typography
            sx={{
              color: faqs[boxId as number].titleColor,
              fontFamily: 'Inter',
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '30.6px',
            }}
          >
            {faqs[boxId as number].title}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: '#7E8180',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
          }}
        >
          {faqs[boxId as number].description}
        </Typography>
      </Box>
    </Motion>
  )
}

export default FAQBox
