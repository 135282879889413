import React from 'react'
import { Box, Typography, styled, useMediaQuery } from '@mui/material'

// const CircleContainer = styled('div')(({ theme }) => ({
//   width: 40,
//   height: 40,
//   background: '#0F1113',
//   boxShadow:
//     '0px 0.5px 0px rgba(255, 255, 255, 0.15), inset 0px 0.5px 0px rgba(255, 255, 255, 0.16)',
//   borderRadius: '50%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   marginRight: '16px',
// }))

// const NumberInsideCircle = styled(Typography)(({ theme }) => ({
//   fontFamily: 'Hellix',
//   fontWeight: 600,
//   fontSize: '20px',
//   lineHeight: '120%',
//   letterSpacing: '-0.01em',
//   color: '#FFFFFF',
// }))

const KeyFeatureBox: React.FC<{
  id: number
  headingText?: string
  descriptionText?: string
  subTexts?: string[]
  width?: string
}> = ({ id, headingText, descriptionText, subTexts, width }) => {
  // const isDown1200 = useMediaQuery('(max-width:1200px)')
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#0F1113',
        borderRadius: '12px',
        padding: '16px',
        border: '0.5px solid rgba(255, 255, 255, 0.16)',
        width: width || '100%',
        flex: '48%',
        gap: '24px',
      }}
    >
      {/* <CircleContainer>
        <NumberInsideCircle>{id}</NumberInsideCircle>
      </CircleContainer> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        {headingText && (
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '-0.01em',
              color: '#FFFFFF',
            }}
          >
            {headingText}
          </Typography>
        )}

        {descriptionText && (
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: '14px',
              color: '#7E8180',
              lineHeight: '125%',
            }}
          >
            {descriptionText}
          </Typography>
        )}

        {subTexts &&
          subTexts?.length > 0 &&
          subTexts.map((subText, index) => (
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '14px',
                color: '#7E8180',
              }}
              key={index}
            >
              {subText}
            </Typography>
          ))}
      </Box>
    </Box>
  )
}

export default KeyFeatureBox
