import { useWeb3Modal } from '@web3modal/wagmi/react'
import BalanceAndAddressBox from '../BalanceAndAddressBox'
import TemporaryDrawer from '../Drawer/drawer'
import Flex from '../Flex'
import WhiteButton from '../WhiteButton'
import React from 'react'
import { useAccount } from 'wagmi'
import CustomDrawer from './CustomDrawer'
import { Logo } from '../../assets'
import BalanceBox from '../../components/BalanceBox'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// define props
interface IMobileNavbar {
  setShowConnectWalletModal: React.Dispatch<React.SetStateAction<boolean>>
  account: string | null | undefined
}

const MobileNavbar = ({
  setShowConnectWalletModal,
  account,
}: IMobileNavbar) => {
  const { open, close } = useWeb3Modal()
  const { address } = useAccount()
  const navigate = useNavigate()

  return (
    <>
      <Flex jc="space-between" w="100%" h="100%">
        <img
          src={Logo}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/')
          }}
          alt="logo"
        />

        <Box
          sx={{
            display: 'flex',

            alignItems: 'center',
          }}
        >
          {!address ? (
            <WhiteButton
              text="Connect Wallet"
              w={'150px'}
              br="40px"
              fs={'16px'}
              lh="19px"
              fw={500}
              fm="Inter"
              h="40px"
              sx={{
                mr: '20px',
              }}
              onClick={() => open()}
            />
          ) : (
            <BalanceBox />
          )}

          <TemporaryDrawer />
        </Box>
      </Flex>
    </>
  )
}

export default MobileNavbar
