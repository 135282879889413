import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Container } from './styles'
import {
  ConnectWalletArrow,
  ConnectWalletButtonIcon,
  SkullIcon,
  TextLogo,
} from '../../assets/index'
import navList from '../../components/NavItem/list'
import NavItem from '../../components/NavItem'
import { useLocation, useNavigate } from 'react-router-dom'
import BalanceAndAddressBox from '../../components//BalanceAndAddressBox'

import {
  useAppDispatch,
  useAppSelector,
  useSignMessageAndRegisterAccount,
} from '../../hooks/'
import { setKycStatus, setWalletAddress } from '../../redux/slices/userSlice'
import GiftBox from '../../components/GiftBox'
import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import MobileNavbar from './MobileNavbar'
import { useWeb3Modal, useWeb3ModalTheme } from '@web3modal/wagmi/react'
import { useAccount, useDisconnect } from 'wagmi'
import ReferralButton from './ReferralButton'
import SignatureModal from '../../components/SignatureModal'
import ConnectButton from '../../components/Buttons/ConnectButton'

// import TemporaryDrawer from './drawer.tsx'
interface INavbar {
  isLoading: boolean
  giftBannerHeight: string
}
const Navbar: React.FC<INavbar> = ({ isLoading, giftBannerHeight }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { disconnect } = useDisconnect()

  const [openSignautureModal, setOpenSignautureModal] = useState(false)

  const { address, chain } = useAccount()
  console.log('address', address)

  const user = useAppSelector(state => state.user)

  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()

  const { open, close } = useWeb3Modal()

  // const { setTheme } = useWeb3ModalTheme()

  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false)
  console.log('showConnectWalletModal', showConnectWalletModal)
  const dispatch = useAppDispatch()
  const handleProfileIcon = () => {
    if (user.isLoggedIn) disconnectWalletAndProfile()
    else navigate('/portfolio')
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown900 = useMediaQuery('(max-width:900px)')
  const isDown1050 = useMediaQuery('(max-width:1050px)')

  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const elligibleForGiftCard = address

  const mainAppToken = useAppSelector(state => state.user.mainAppToken)

  useEffect(() => {
    // setTheme({
    //   themeVariables: {
    //     '--w3m-z-index': '1000',
    //   },
    // })
    // setThemeVariables({
    //   '--w3m-z-index': 1000,
    // })
  }, [])

  // useEffect(() => {
  //   if (address && user.walletAddres === '') dispatch(setWalletAddress(address))
  //   else {
  //     if (address !== user.walletAddres) {
  //       disconnectWalletAndProfile()
  //       navigate('/')
  //       dispatch(
  //         setSnackbar({
  //           open: true,
  //           message: 'Connect your wallet again',
  //           severity: 'error',
  //         }),
  //       )
  //     }
  //   }
  // }, [address])

  useEffect(() => {
    console.log('useEffect address', { address, user })

    // if (!address) {
    //   disconnect()
    //   disconnectWalletAndProfile()
    // }

    if (address && user.walletAddress === '') {
      console.log('setWalletAddress cond1', address)

      dispatch(setWalletAddress(address))
      // signTypedData()
      setOpenSignautureModal(true)
    }

    if (
      address &&
      user.walletAddress !== '' &&
      address !== user.walletAddress
    ) {
      console.log('setWalletAddress cond2', address)
      dispatch(setWalletAddress(address))
      dispatch(setKycStatus('incomplete'))

      // signTypedData()
      setOpenSignautureModal(true)
    }
  }, [address, chain])

  // useEffect(() => {
  //   if (address && !mainAppToken) {
  //     //sign message from user once securing address
  //     // don't sign if you are on localhost
  //     // if (window.location.hostname !== 'localhost')
  //     //signTypedData()
  //   }
  // }, [address, mainAppToken])

  // watchAccount(account => {
  //   console.log('account changed', account)
  //   if (account.status === 'connected') signTypedData()
  // })

  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [isSticky, setSticky] = useState(false)

  const handleScroll = () => {
    const currentScrollPos = window.scrollY
    setSticky(currentScrollPos > 0)
    setPrevScrollPos(currentScrollPos)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos])

  return (
    <Container
      container
      padding={isDown1200 ? '12px 15px' : '10px 24px 10px 24px'}
      sx={{
        position: isSticky ? 'fixed' : 'absolute',
        top: !isSticky ? giftBannerHeight : 0,
        width: '100%',
        height: isSticky ? '70px' : 'auto',
        backdropFilter: isSticky ? 'blur(8px)' : 'none',
        backgroundColor: isSticky ? 'rgba(0,0,0,0.72)' : 'transparent',
        opacity: isSticky ? 1 : 0.8,
        zIndex: isSticky ? 1001 : 1000,
        transition:
          'background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, height 0.3s ease-in-out',
        marginBottom: '100px',
      }}
    >
      {isDown1050 ? (
        <MobileNavbar
          setShowConnectWalletModal={setShowConnectWalletModal}
          account={address}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexDirection: 'row',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'start'}
            sx={{
              cursor: 'pointer',
              float: 'left',
              flex: address || elligibleForGiftCard ? 1 : 0,
            }}
            onClick={() => navigate('/')}
          >
            <img src={TextLogo} alt="logo" width="130" height="40" />
          </Box>
          <Box
            sx={{
              backgroundColor: '#000000',
              border: '0.5px solid #FFFFFF33',
              gap: '2px',
              borderRadius: '80px',
              padding: '5px 3px',
              alignSelf: 'center',
              maxHeight: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {navList.map(nav => {
              if (!address && nav.name === 'Portfolio') return null
              else
                return (
                  <NavItem
                    key={nav.name}
                    text={nav.name}
                    href={nav.href}
                    target={nav.target}
                    textStyles={{
                      fontSize: 15,
                      fontWeight: '400',
                      fontFamily: 'Inter',
                      border: 'none',
                      backgroundColor:
                        pathname === nav.href
                          ? 'rgba(111, 235, 124, 0.03)'
                          : 'transparent',
                      color: pathname === nav.href ? '#63EA71' : '#fff',
                    }}
                    active={pathname === nav.href}
                  />
                )
            })}
          </Box>
          <Box
            sx={{
              maxHeight: '40px',
              gap: '8px',
              display: address || elligibleForGiftCard ? 'flex' : 'none',
              float: 'right',
              flex: 1,
              justifyContent: 'right',
            }}
          >
            {elligibleForGiftCard && <GiftBox mobile={false} />}
            {address && <BalanceAndAddressBox />}
            {address && (
              <Box
                sx={{
                  backgroundColor: 'rgba(15, 17, 19, 1)',
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                  textAlign: 'center',
                }}
              >
                <img
                  src={user.isLoggedIn ? user.profilePicture : SkullIcon}
                  style={{
                    height: '32px',
                    width: '32px',
                    borderRadius: user.isLoggedIn ? '15px' : '0px',
                    cursor: 'pointer',
                    marginTop: '4px',
                  }}
                  alt="profile-icon"
                  onClick={handleProfileIcon}
                />
              </Box>
            )}
          </Box>
          {!address && (
            <ConnectButton text="Connect Wallet" onClick={() => open()} />
          )}
        </Box>
      )}

      <SignatureModal
        open={openSignautureModal}
        setOpen={setOpenSignautureModal}
      />
    </Container>
  )
}
export default Navbar
