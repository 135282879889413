import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'

import navList from '../NavItem/list'
import { useAppDispatch, useAppSelector } from '../../hooks/'
import NavItem from '../NavItem'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import Web3AuthModalButton from '../Web3AuthModal/Web3AuthModal'
import OrangeButton from '../OrangeButton'
import { MenuIcon, UserIcon } from '../../assets/index'
import { logout } from '../../redux/slices/userSlice'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CustomDrawer from '../../components/Navbar/CustomDrawer'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

export default function TemporaryDrawer() {
  const isLoggedIn = useAppSelector(state => state.user.isLoggedIn)
  const userAvatar = useAppSelector(state => state.user.profilePicture)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [showConnectWalletModal, setShowConnectWalletModal] =
    React.useState(false)

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setState({ ...state, [anchor]: open })
    }

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' ? 'auto' : 250, position: 'relative' }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <CustomDrawer onItemClick={toggleDrawer(anchor, false)} />
    </Box>
  )

  return (
    <React.Fragment key="top">
      {/* <MenuIcon
        onClick={toggleDrawer('top', true)}
        sx={{
          cursor: 'pointer',
          height: '24px',
          width: '24px',
          fontSize: '30px',
        }}
      /> */}
      <Box
        onClick={toggleDrawer('top', true)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      >
        <img src={MenuIcon} alt="menu icon" />
      </Box>
      <Drawer
        anchor="top"
        open={state['top']}
        onClose={toggleDrawer('top', false)}
      >
        {list('top')}
      </Drawer>
    </React.Fragment>
  )
}
