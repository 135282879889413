import React, { useEffect, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  styled,
  tableRowClasses,
  backdropClasses,
} from '@mui/material'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import { useNavigate } from 'react-router-dom'
import { removeXFromROIAndMakeItPercentage } from '../../utils'

type Project = {
  id: string
  name: string
  bannerImageUrl: string
  logoUrl: string
  projectTitle: string
  totalRaise: number
  startsIn: number
  saleType: string
  marketMaker: string
  registrationStarted: boolean
  registrationEndDate: number
  routeName: string
  roi: string
}

export const StyledHeadTableCell = styled(TableCell)({
  backgroundColor: '#000000',
  borderBottom: '0.5px solid #0F1113',
  color: '#767676',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  height: '48px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
  padding: '0px 20px',
})
export const StyledBodyTableCell = styled(TableCell)({
  height: '72px',
  borderBottom: '0.5px solid #1D1F21',
  color: '#B1B4B3',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
  padding: '0px 20px',
  lineHeight: '15px',
})

const CompletedSales: React.FC<{ tokenSales: any }> = ({ tokenSales }) => {
  const [projects, setProjects] = useState<Project[]>(tokenSales)
  const [showMore, setShowMore] = useState(false)
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown540 = useMediaQuery('(max-width:540px)')
  const navigate = useNavigate()

  useEffect(() => {
    setProjects(
      tokenSales.sort((a: any, b: any) => b.order - a.order).slice(0, 7),
    )
  }, [tokenSales])
  const handleShowMore = () => {
    if (projects.length === 7) {
      // If currently showing 7 projects, show all projects
      setProjects(tokenSales.sort((a: any, b: any) => b.order - a.order))
    } else {
      // If showing all projects, reset to showing only 7 projects
      setProjects(
        tokenSales.sort((a: any, b: any) => b.order - a.order).slice(0, 7),
      )
    }
  }

  return (
    <Box>
      <Box width={'100%'} display={'flex'} flexDirection={'column'}>
        <Typography
          fontWeight={600}
          fontSize={isDown767 ? 32 : 56}
          lineHeight={isDown767 ? '32px' : '61.5px'}
          letterSpacing={'0px'}
          textAlign={'left'}
          fontFamily={'Hellix'}
          color="#FFFFFF"
          mb={1}
        >
          Completed Sales
        </Typography>
        <Typography
          fontFamily={'Inter'}
          color="#7E8180"
          fontSize={isDown767 ? 17 : 18}
          lineHeight={'27px'}
          fontWeight={400}
          pb={'24px'}
        >
          Previous sales that were hosted on our platform.
        </Typography>
        <Box
          width={'100%'}
          maxWidth={`${window.innerWidth - 40}px`}
          display={'flex'}
          flexDirection={'column'}
          paddingLeft={'0px'}
          paddingTop={'0px'}
        >
          <TableContainer
            component={Paper}
            sx={{
              height: '100%',
              // maxHeight: '574px',
              borderRadius: '16px',
              border: '0.5px solid #1D1F21',
              boxShadow: 'none',
              backgroundImage: 'none',
              flexShrink: 1,
              overflowX: 'auto',
              // maxWidth: `calc(${window.innerWidth}px - 40px)`,
              width: '100%',
              '&::-webkit-scrollbar': {
                display: 'flex',
                height: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#313335',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#0F1113',
              },
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                // minHeight: '570px',
                // height: '100%',
                // flexShrink: 1,
                // width: '100%',
                height: '100%',
                flexShrink: 1,
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    textWrap: 'nowrap',
                  }}
                >
                  <StyledHeadTableCell>Projects</StyledHeadTableCell>
                  <StyledHeadTableCell>Total Raise</StyledHeadTableCell>
                  <StyledHeadTableCell>All Time High</StyledHeadTableCell>
                  <StyledHeadTableCell>Type</StyledHeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  overflowY: 'hidden',
                  overflowX: 'auto',
                  backgroundColor: '#0F1113',
                }}
              >
                {projects.map((project: any) => (
                  <TableRow
                    key={project.id}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#000000',
                      },
                      [`&.${tableRowClasses.root}`]: {
                        height: '56px',
                        maxHeight: '56px',
                      },
                      '&:last-child': {
                        borderBottom: '0px solid transparent',
                      },
                      '&:last-child td': {
                        borderBottom: '0px solid transparent',
                      },
                    }}
                    onClick={() => navigate(`/project/${project.routeName}`)}
                  >
                    <StyledBodyTableCell
                      size={'small'}
                      sx={{
                        minWidth: isDown767 ? '220px' : '250px',
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <img
                          src={project.logoUrl}
                          alt={project.projectTitle}
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 10,
                            border: '0.5px solid #1D1F21',
                          }}
                          loading="lazy"
                        />
                        <Box ml={'12px'}>
                          <Typography
                            sx={{
                              color: '#FFFFFF',
                              fontSize: '15px',
                              fontWeight: 400,
                              lineHeight: '22.5px',
                            }}
                          >
                            {project.projectTitle}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#7E8180',
                              fontSize: '12px',
                              fontWeight: 400,
                              lineHeight: '15.6px',
                              letterSpacing: '0.5px',
                            }}
                          >
                            {project.saleType}
                          </Typography>
                        </Box>
                      </Box>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      size={'small'}
                      sx={{
                        minWidth: isDown767 ? '200px' : '250px',
                      }}
                    >
                      {project.totalRaise === -2
                        ? 'Launch Drop'
                        : '$' + project.totalRaise.toLocaleString('en')}
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      size={'small'}
                      sx={{
                        minWidth: isDown767 ? '150px' : '200px',
                      }}
                    >
                      {project.roi === 'TBD'
                        ? project.roi
                        : removeXFromROIAndMakeItPercentage(project.roi)}
                    </StyledBodyTableCell>
                    <StyledBodyTableCell
                      size={'small'}
                      sx={{
                        minWidth: isDown767 ? '150px' : '200px',
                      }}
                    >
                      {project.saleType}
                    </StyledBodyTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Box mt={2} textAlign="center">
        <OutlinedButton
          onClick={handleShowMore}
          sx={{ height: '48px', width: '100%' }}
        >
          {projects.length === 7 ? 'Show More' : 'Show Less'}
        </OutlinedButton>
      </Box>
    </Box>
  )
}

export default CompletedSales
