import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledSubText, StyledText } from '.'
import StatsBox from './StatsBox'
import ChanceOfWinningBox from './ChanceOfWinningBox'
import RenderContainer from './RenderContainer'
import RenderSolanaAddress from './RenderSolanaAddress'
import { useUpdateSolanaAddressInParticipationMutation } from '../../services/modules/participations'

const RenderParticipationPhase = ({
  score,
  totalParticipants,
  totalAssetsConnected,
  winningChances,
  idoData,
  multiplierData,
  _userParticipation,
}: any) => {
  const [solanaAddressUpdated, setSolanaAddressUpdated] = useState(false)

  const [
    updateSolanaAddressInParticipation,
    {
      data: solanaAddressInParticipationData,
      isSuccess: isSolanaAddressInParticipationSuccess,
      error: solanaAddressInParticipationError,
    },
  ] = useUpdateSolanaAddressInParticipationMutation()

  useEffect(() => {
    if (solanaAddressInParticipationData) {
      setSolanaAddressUpdated(true)
    }
  }, [solanaAddressInParticipationData])

  const renderParticipationPhase = () => {
    if (
      idoData._id === '667165f45403b3d9bccee36d' &&
      !solanaAddressUpdated &&
      _userParticipation &&
      !_userParticipation?.solanaAddress
    ) {
      return (
        <RenderContainer
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          idoData={idoData}
          sideComponent={
            <RenderSolanaAddress
              idoData={idoData}
              score={score}
              totalParticipants={totalParticipants}
              totalAssetsConnected={totalAssetsConnected}
              multiplierData={multiplierData}
              userParticipation={_userParticipation}
              updateSolanaAddressInParticipation={
                updateSolanaAddressInParticipation
              }
            />
          }
          type="registered"
        />
      )
    } else
      return (
        <RenderContainer
          idoData={idoData}
          score={score}
          totalParticipants={totalParticipants}
          totalAssetsConnected={totalAssetsConnected}
          multiplierData={multiplierData}
          sideComponent={
            <ChanceOfWinningBox
              winningChances={winningChances}
              ticketPrice={
                idoData.amountToRaise === -1
                  ? -1
                  : idoData.amountToRaise / idoData.totalInvestors
              }
            />
          }
          type="registered"
        />
      )
  }
  return <>{renderParticipationPhase()}</>
}

export default RenderParticipationPhase
