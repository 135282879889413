import { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import OrangeButton from '../../components//OrangeButton'
import { useAccount } from 'wagmi'
import GreenButton from '../../components/GreenButton'
import { useGetUserKyc } from '../../hooks'

const KYCButton = ({ component }: { component: string }) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')

  const [kycHandler, setKycHandler] = useState<any>()

  const { isLoading } = useGetUserKyc()

  const { address } = useAccount()

  useEffect(() => {
    try {
      console.info('BlockpassKYCConnect start intialize.')

      const blockpass = new window.BlockpassKYCConnect('ape_terminal_9eafe', {
        refId: address,
      })
      console.info('BlockpassKYCConnect 🚀 ~ is now intialized:', blockpass)

      // setKycHandler(blockpass)
      blockpass.on('KYCConnectLoad', () => {
        console.info('BlockpassKYCConnect connected')
      })

      blockpass.startKYCConnect()
    } catch (e) {
      console.error(e, 'BlockpassKYCConnect initialization error')
    }
  }, [address])

  if (component === 'participation') {
    return (
      <GreenButton
        fs={15}
        lh="22.5px"
        fm="Inter"
        fw={400}
        br="24px"
        text="Complete KYC"
        mt={5}
        w="200px"
        id="blockpass-kyc-connect"
      />
    )
  }

  if (component === 'homepage') {
    return (
      <GreenButton
        fs={15}
        lh="22.5px"
        fm="Inter"
        fw={400}
        br="24px"
        text={'Complete KYC'}
        h={'40px'}
        outlined={true}
        w={'100%'}
        id="blockpass-kyc-connect"
        sx={{
          minWidth: '71px',
          border: '1px solid #63EA71',
          padding: '3px 6px',
          color: '#63EA71',
          backgroundColor: 'transparent',
          '&:hover': {
            border: '1px solid #63EA71',
            backgroundColor: 'transparent',
          },
        }}
      />
    )
  }

  return (
    <GreenButton
      fs={15}
      lh="22.5px"
      fm="Inter"
      fw={400}
      br="24px"
      text={'Complete KYC'}
      h={'40px'}
      outlined={true}
      w={isDown1000 ? '100%' : 'fit-content'}
      id="blockpass-kyc-connect"
      sx={{
        minWidth: '71px',
        border: '1px solid #08493C',
        padding: '3px 16px',
        color: '#63EA71',
        backgroundColor: 'transparent',
        '&:hover': {
          border: '1px solid #27B079',
          backgroundColor: 'transparent',
        },
      }}
    />
  )
}

export default KYCButton
