import { Logo } from '../../assets/'
import { Box, Typography, Link, useMediaQuery, useTheme } from '@mui/material'
import moment from 'moment'
import { ReactComponent as TelegramNew } from '../../assets/telegram_new.svg'
import { ReactComponent as TwitterNew } from '../../assets/twitter_new.svg'
import SouthEastIcon from '@mui/icons-material/SouthEast'

const Links = [
  { icon: TelegramNew, link: 'https://t.me/ApeTerminalChat' },
  { icon: TwitterNew, link: 'https://twitter.com/apeterminal' },
]

const Footer = () => {
  const theme = useTheme()
  const isDown880 = useMediaQuery('(max-width:880px)')

  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        borderTop: '1px solid #1D1F21',
        paddingTop: '64px',
        paddingBottom: '24px',
        paddingX: isDown880 ? '20px' : '60px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: '#000000',
          maxWidth: '1200px',
          width: '100%',
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={isDown880 ? 'column' : 'row'}
          width={'100%'}
          paddingBottom={'32px'}
          borderBottom={isDown880 ? 'none' : '1px solid #1D1F21'}
        >
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            flexDirection={isDown880 ? 'column' : 'row'}
            width={'100%'}
            alignItems={'center'}
            gap={isDown880 ? '40px' : '57px'}
            paddingBottom={isDown880 ? '40px' : '0px'}
          >
            <img
              src={Logo}
              style={{
                width: '33px',
                height: '32px',
                marginRight: 'auto',
              }}
              alt="Logo"
            />
            <Box
              display={!isDown880 ? 'flex' : 'none'}
              width={'100%'}
              flexDirection={'row'}
            >
              <RenderLinks
                linksArray={[
                  { text: 'Affiliates', link: '/affiliates', comingSoon: true },
                  { text: 'FAQ', link: '/faq' },
                  { text: 'Yield App', link: 'https://app.apeterminal.io' },
                  {
                    text: 'Transparency',
                    link: '/transparency',
                    samePage: true,
                  },
                ]}
                customStyles={{
                  justifyContent: isDown880 ? 'space-between' : 'flex-start',
                }}
              />
            </Box>
            <Box
              display={isDown880 ? 'flex' : 'none'}
              width={'100%'}
              gap={isDown880 ? '8px' : '0px'}
              flexDirection={'column'}
            >
              <RenderLinks
                linksArray={[
                  { text: 'Affiliates', link: '/affiliates', comingSoon: true },
                  { text: 'Yield App', link: 'https://app.apeterminal.io' },
                ]}
                customStyles={{
                  justifyContent: isDown880 ? 'space-between' : 'flex-start',
                }}
              />
              <RenderLinks
                linksArray={[
                  { text: 'FAQ', link: '/faq' },
                  {
                    text: 'Transparency',
                    link: '/transparency',
                    samePage: true,
                  },
                ]}
                customStyles={{
                  justifyContent: isDown880 ? 'space-between' : 'flex-start',
                }}
              />
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            flexDirection={isDown880 ? 'column' : 'row'}
            width={'100%'}
            alignItems={'center'}
            gap={isDown880 ? '12px' : '16px'}
            borderTop={isDown880 ? '1px solid #1D1F21' : 'none'}
            paddingTop={isDown880 ? '40px' : '0px'}
          >
            <Typography
              fontSize={16}
              fontWeight={400}
              lineHeight="16px"
              fontFamily={'Inter'}
              color={'#7E8180'}
              marginRight={isDown880 ? 'auto' : '0px'}
            >
              Join our community
            </Typography>
            <RenderLinksWithIcon
              linksArray={Links}
              width={isDown880 ? '100%' : '140px'}
              iconWidth={isDown880 ? '100%' : '64px'}
            />
          </Box>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
          flexDirection={isDown880 ? 'column' : 'row'}
          alignSelf={'center'}
          paddingTop={isDown880 ? '8px' : '24px'}
          gap={'24px'}
        >
          <Typography
            fontSize={15}
            fontWeight={400}
            lineHeight="22px"
            fontFamily={'Inter'}
            color={'#7E8180'}
            width={'inherit'}
            sx={{
              textWrap: 'pretty',
            }}
          >
            Ape Terminal © {moment().format('YYYY')} . All rights reserved.
          </Typography>
          <Box
            gap={'16px'}
            display={'flex'}
            width={'inherit'}
            justifyContent={isDown880 ? 'flex-start' : 'flex-end'}
          >
            <Link
              fontSize={15}
              fontWeight={400}
              lineHeight="22px"
              fontFamily={'Inter'}
              color={'#7E8180'}
              href={'/terms-of-service'}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                textWrap: 'pretty',
                '&:hover': { color: '#63ea71' },
              }}
            >
              Terms & Conditions
              <SouthEastIcon
                sx={{
                  fontSize: 'small',
                  verticalAlign: 'middle',
                  marginLeft: '3px',
                }}
              />
            </Link>
            <Link
              fontSize={15}
              fontWeight={400}
              lineHeight="22px"
              fontFamily={'Inter'}
              color={'#7E8180'}
              href={'/privacy-policy'}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                '&:hover': { color: '#63ea71' },
              }}
            >
              Privacy Policy
              <SouthEastIcon
                sx={{
                  fontSize: 'small',
                  verticalAlign: 'middle',
                  marginLeft: '3px',
                }}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const RenderLinks = ({
  linksArray,
  customStyles,
}: {
  linksArray: {
    text: string
    link: string
    comingSoon?: boolean
    samePage?: boolean
  }[]
  customStyles?: any
}) => {
  const isDown880 = useMediaQuery('(max-width:880px)')

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '24px',
        width: 'inherit',
        justifyContent: 'space-between',
        ...customStyles,
      }}
    >
      {linksArray.map((link, index) => (
        <Link
          key={index}
          sx={{
            color: '#C8CBCA',
            whiteSpace: 'nowrap',
            width: isDown880 ? '100%' : 'fit-content',
            '&:hover': {
              opacity: 0.9,
              color: '#63ea71',
              textDecoration: 'none',
            },
          }}
          href={link.comingSoon ? '' : link.link}
          target={link.samePage ? '_self' : '_blank'}
          rel="noopener noreferrer"
        >
          {link.text}
        </Link>
      ))}
    </Box>
  )
}

const RenderLinksWithIcon = ({
  linksArray,
  width,
  iconWidth,
}: {
  linksArray: { link: string; icon: any }[]
  width?: string
  iconWidth?: string
}) => (
  <Box
    sx={{
      display: 'flex',
      gap: '4px',
      background:
        'linear-gradient(180deg, rgba(56, 56, 56, 0.47) 0%, #1C1C1C 100%)',
      boxShadow:
        '0px 1px 2px rgba(0, 0, 0, 0.15), inset 0px 1px 0px rgba(255, 255, 255, 0.12)',
      borderRadius: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px',
      height: '48px',
      width: width,
    }}
  >
    {linksArray.map((link, index) => (
      <Link
        key={index}
        sx={{
          color: '#C8CBCA',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '32px',
          backgroundColor: '#000000',
          height: '40px',
          width: iconWidth,
          '&:hover': { color: '#FF7722' },
        }}
        href={link.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <link.icon className="orange_hover_svg" />
      </Link>
    ))}
  </Box>
)

export default Footer
