import { Button, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Link } from 'react-router-dom'

type Props = {
  img?: string
  text: string
  href: string
  textStyles?: any
  active?: boolean
  IconSrc?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  padding?: string
  IconSvg?: any
  target?: string
}

function NavItem({
  img,
  text,
  href,
  textStyles,
  active,
  IconSrc,
  padding,
  IconSvg,
  target,
}: Props) {
  return (
    <Link
      to={href}
      style={{
        textDecoration: 'none',
      }}
    >
      <Button
        sx={{
          backgroundColor: active ? '#171717' : 'transparent',
          border: `0.5px solid 
      ${active ? 'rgba(255, 255, 255, 0.16)' : 'transparent'}
     `,
          boxShadow: active ? '-70px 50px 80px 0px #6FEB7C08 inset' : 'none',
          padding: '0 12px',
          height: 34,
          borderRadius: '25px',
          width: 'auto',
          mb: 0,
          transition: 'all 0.2s',
          '&:hover': {
            border: `0.5px solid 
            ${active ? 'rgba(255, 255, 255, 0.16)' : 'transparent'}
           `,
            backgroundColor: '#171717',
            boxShadow: '-70px 50px 80px 0px #6FEB7C08 inset',
          },
        }}
      >
        {IconSrc && <IconSrc sx={{ mr: 4 }} />}
        {IconSvg && (
          <IconSvg
            style={{
              height: '20px',
              width: '20px',
              fill: active ? '#FF7722' : '#F4FDAB',
            }}
          />
        )}

        {/* <img src={img} width={26} height={26} /> */}
        <Typography
          color={active ? '#63EA71' : 'rgba(255, 255, 255, 1)'}
          fontSize={14}
          fontWeight={500}
          lineHeight={'22px'}
          fontFamily={'Inter'}
          textTransform={'none'}
          // ml={1}
        >
          {text}
        </Typography>
      </Button>
    </Link>
  )
}

export default NavItem
