import { logout, setWalletAddress } from '../redux/slices/userSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '.'
import { useAccount, useDisconnect } from 'wagmi'

const useDisconnectWalletAndProfile = () => {
  const { address } = useAccount()

  const { disconnect } = useDisconnect()

  const { web3AuthInstance } = useAppSelector(state => state.app)

  const dispatch = useDispatch()

  function removeWalletAddresFromRedux() {
    dispatch(setWalletAddress(''))
  }

  function disconnectWallet() {
    if (address) {
      console.log('disconnect wallet')
      sessionStorage.removeItem('connection-type')
      sessionStorage.removeItem('account')
      sessionStorage.removeItem('signature')

      // connector.deactivate && connector.deactivate()
      // connector.resetState && connector.resetState()
      disconnect()
      removeWalletAddresFromRedux()
    }
  }

  async function disconnectProfile() {
    if (web3AuthInstance) {
      try {
        await web3AuthInstance.logout()
        dispatch(logout())
      } catch (e) {
        console.error(e, 'from web3Auth instance error')
        dispatch(logout())
      } finally {
        dispatch(logout())
      }
    }
  }

  const disconnectWalletAndProfile = async () => {
    disconnectWallet()

    await disconnectProfile()
  }

  return { disconnectWalletAndProfile, disconnectProfile, disconnectWallet }
}

export default useDisconnectWalletAndProfile
