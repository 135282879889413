import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum TransactionTypes {
  ADD_LIQUIDITY,
  REMOVE_LIQUIDITY,
  SWAP,
  APPROVE,
}

export enum TransactionStatus {
  FAILED,
  SUCCESS,
  PENDING,
}

interface Transaction {
  type: TransactionTypes
  status: TransactionStatus
  hash: string
}
interface WalletState {
  selectedWallet: any
  txInProgress: boolean
  transaction: Transaction | null
}

const initialState: WalletState = {
  selectedWallet: '',
  txInProgress: false,
  transaction: null,
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setSelectedWallet: (state, action: PayloadAction<any>) => {
      state.selectedWallet = action.payload
    },
    setTxInProgress: (state, action: PayloadAction<boolean>) => {
      state.txInProgress = action.payload
    },
    setTransaction: (state, action: PayloadAction<Transaction>) => {
      state.transaction = action.payload
    },
  },
})

export const { setSelectedWallet, setTxInProgress, setTransaction } =
  walletSlice.actions

export default walletSlice.reducer
