export const APPLICATION_URL = 'http://localhost:3000'
export const APPLICATION_NAME = 'Ape Launchpad'

//export const DEV_SERVER_URL = 'https://backend.droxlab.com/api/'
export const SERVER_URL = 'https://vmwppqa4er.us-east-1.awsapprunner.com/api'
//export const SERVER_URL = 'http://localhost:3005/api/'

export const LOCAL_SERVER_URL = 'http://localhost:3005/api/'
//export const SERVER_URL = 'https://backend.droxlab.com/api/'
// export const DEV_SERVER_URL = 'http://localhost:3005/api/'
export const DEV_SERVER_URL = 'https://backend.droxlab.com/api/'

//
//export const USDT_ADDRESS = '0xdAC17F958D2ee523a2206206994597C13D831ec7'
export const USDT_ADDRESS = '0x31a1003ab3B143e57fF0a455F71774772Ba36617'
//export const USDC_ADDRESS = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'

export const USDC_ADDRESS = '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'

//export const USDC_ADDRESS = '0xCf8EA1c5a4421a01bc769D556Fa0f01ebd0523a2'

export const TWITTER_CONNECTED = false
export const KYC_STATUS = true
export const LOGGED_IN = false

export const SHOW_TWITTER = LOGGED_IN
