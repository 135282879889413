import { Box, Divider, Typography, styled, useMediaQuery } from '@mui/material'
interface StyledTypographyProps {
  isDown1000?: boolean // Define your custom prop
}

const StyledHeading = styled(Typography)<StyledTypographyProps>(
  ({ theme, isDown1000 }) => ({
    fontSize: '14px',
    color: '#767676',
    fontWeight: 400,
    lineHeight: '16.8px',
    fontFamily: 'Inter',
    alignSelf: isDown1000 ? 'center' : 'flex-start',
    [theme.breakpoints.down('smd')]: {},
  }),
)

const StyledValue = styled(Typography)<StyledTypographyProps>(
  ({ theme, isDown1000 }) => ({
    fontSize: '20px',
    color: '#ffffff',
    fontWeight: 600,
    lineHeight: '28px',
    fontFamily: 'Hellix',
    alignSelf: isDown1000 ? 'center' : 'flex-start',
    [theme.breakpoints.down('smd')]: {},
  }),
)

const StatsBox = ({
  score,
  totalParticipants,
  totalAssetsConnected,
  referralMultiplier,
}: any) => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  return (
    <Box
      border={'0.5px solid rgba(255, 255, 255, 0.16)'}
      padding={'16px'}
      borderRadius={'12px'}
      mt={isDown1000 ? '16px' : '40px'}
    >
      <Box
        display={'flex'}
        flexDirection={isDown1000 ? 'column' : 'row'}
        justifyContent={'space-between'}
      >
        <Box
          width={'100%'}
          paddingBottom={'12px'}
          flexDirection={isDown1000 ? 'row' : 'column'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <StyledHeading isDown1000={isDown1000}>Your Score</StyledHeading>
          <StyledValue
            sx={{
              color: '#FF7722',
            }}
            isDown1000={isDown1000}
          >
            {score ? Number(score).toFixed(2) : 0}
          </StyledValue>
        </Box>
        <Box
          width={'100%'}
          borderLeft={
            isDown1000 ? 'none' : '0.5px solid rgba(255, 255, 255, 0.16)'
          }
          borderTop={
            isDown1000 ? '0.5px solid rgba(255, 255, 255, 0.16)' : 'none'
          }
          paddingLeft={isDown1000 ? 0 : '24px'}
          paddingTop={isDown1000 ? '12px' : 0}
          paddingBottom={'12px'}
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={isDown1000 ? 'row' : 'column'}
        >
          <StyledHeading isDown1000={isDown1000}>
            Total Participants
          </StyledHeading>
          <StyledValue isDown1000={isDown1000}>
            {totalParticipants ? totalParticipants.toLocaleString('en') : 0}
          </StyledValue>
        </Box>
      </Box>
      <Divider
        sx={{
          height: '0.5px',
        }}
      />
      <Box
        display={'flex'}
        flexDirection={isDown1000 ? 'column' : 'row'}
        justifyContent={'space-between'}
      >
        <Box
          width={'100%'}
          paddingTop={'12px'}
          paddingBottom={isDown1000 ? '12px' : 0}
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={isDown1000 ? 'row' : 'column'}
        >
          <StyledHeading isDown1000={isDown1000}>
            Total Assets Connected
          </StyledHeading>
          <StyledValue isDown1000={isDown1000}>
            {' '}
            $
            {totalAssetsConnected
              ? Number(totalAssetsConnected.toFixed(2)).toLocaleString('en')
              : 0}
          </StyledValue>
        </Box>
        <Box
          width={'100%'}
          borderLeft={
            isDown1000 ? 'none' : '0.5px solid rgba(255, 255, 255, 0.16)'
          }
          borderTop={
            isDown1000 ? '0.5px solid rgba(255, 255, 255, 0.16)' : 'none'
          }
          paddingLeft={isDown1000 ? 0 : '24px'}
          paddingTop={'12px'}
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={isDown1000 ? 'row' : 'column'}
        >
          <StyledHeading isDown1000={isDown1000}>
            Referral Multiplier
          </StyledHeading>
          <StyledValue isDown1000={isDown1000}>
            {referralMultiplier?.multiplier
              ? `${referralMultiplier?.multiplier}x`
              : '0x'}
          </StyledValue>
        </Box>
      </Box>
    </Box>
  )
}

export default StatsBox
