import { useIsKycVerifiedQuery } from '../services/modules/users'
import { useAppSelector } from './index'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setKycStatus } from '../redux/slices/userSlice'
import { useAccount } from 'wagmi'

const useGetUserKyc = () => {
  const { address } = useAccount()

  const dispatch = useDispatch()

  const kycStatus = useAppSelector(state => state.user.kycStatus)

  console.log(
    '🚀 ~ file: useGetUserKyc.ts:13 ~ useGetUserKyc ~ address:',
    address,
    kycStatus,
  )

  // const refId = email + account

  // useEffect(() => {
  //   if (address && !email) {
  //     setPayload(address)
  //   } else if (address && email) {
  //     setPayload(email + address)
  //   }
  // }, [address, email])

  const { data, isLoading } = useIsKycVerifiedQuery(address!, {
    pollingInterval: 1500000,
    skip: !address,
  })

  useEffect(() => {
    if (data) {
      console.log(
        '🚀 ~ file: useGetUserKyc.ts:23 ~ useGetUserKyc ~ console.1:',
        data.status,
      )
      dispatch(setKycStatus(data.status))
      // setState(data.status)
    }
  }, [data])

  return { userKycStatus: kycStatus, isLoading }
}

export default useGetUserKyc
